<template>
  <div class="Bottominformation">
    <!-- 承诺服务 -->
    <div class="Commitmentservice">
      <ul>
        <li>
          <div class="commitmentservicetop">
            <img src="@/img/icon/proper.png" alt="">
          </div>
          <div class="commitmentservicecenter">
            <span class="commitmentservicetitle">Worry-free Shopping</span>
            <span class="commitmentservicename">Best price every day, no cancellation fees and free returns</span>
          </div>
        </li>
        <li>
          <div class="commitmentservicetop">
            <img src="@/img/icon/logistics.png" alt="">
          </div>
          <div class="commitmentservicecenter">
            <span class="commitmentservicetitle">Full-service Delivery</span>
            <span class="commitmentservicename">Best price every day, no cancellation fees and free returns</span>
          </div>
        </li>
        <li>
          <div class="commitmentservicetop">
            <img src="@/img/icon/map.png" alt="">
          </div>
          <div class="commitmentservicecenter">
            <span class="commitmentservicetitle">Global Craftsmanship</span>
            <span class="commitmentservicename">More than 90% of our furniture and decor is made by global artisans.</span>
          </div>
        </li>
        <li>
          <div class="commitmentservicetop">
            <img src="@/img/icon/foliage.png" alt="">
          </div>
          <div class="commitmentservicecenter">
            <span class="commitmentservicetitle">Committed to Sustainability</span>
            <span class="commitmentservicename">Designing better products with people and the planet in mind</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 网站底部信息 -->
    <div class="bottomwebsite">
      <div class="bottomwebsiteleft">
        <div class="fuhufologo">
          <img src="@/img/icon/FOFUFOlogo.png" alt="">
        </div>

        <div class="telphone">
          <i class="el-icon-phone-outline"></i><span>+ 8617775345655 (9AM-5PM) PST</span>
        </div>
        <div class="telphone">
          <i class="el-icon-message"></i><span>modernfurniture1999@bestfurnitureland.com</span>
        </div>
        <div class="telphone">
          <i class="el-icon-location-outline"></i><span>Headquarters: No.22 Sui Feng Industrial Zone, Chancheng District, Foshan City, Guangdong Province, China</span>
        </div>
      </div>

      <div class="bottomwebsitecenter">
        <ul>
          <li>About Us</li>
          <li><a @click="$router.push('/aboutus')">About Us</a></li>
          <li><a @click="$router.push('/ContactUs')">Contact Us</a> </li>
          <li><a href="">Free Design Services</a></li>
        </ul>
      </div>
      <div class="bottomwebsiteright">
        <ul class="example-2">
            <!-- youtubu -->
            <li class="icon-content">
      <a href="https://www.youtube.com/channel/UCIpghs76jl2HvbHWD3r92cA" target="_blank" data-social="Youtube">
        <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="red" d="M14.712 4.633a1.754 1.754 0 00-1.234-1.234C12.382 3.11 8 3.11 8 3.11s-4.382 0-5.478.289c-.6.161-1.072.634-1.234 1.234C1 5.728 1 8 1 8s0 2.283.288 3.367c.162.6.635 1.073 1.234 1.234C3.618 12.89 8 12.89 8 12.89s4.382 0 5.478-.289a1.754 1.754 0 001.234-1.234C15 10.272 15 8 15 8s0-2.272-.288-3.367z"/><path fill="#ffffff" d="M6.593 10.11l3.644-2.098-3.644-2.11v4.208z"/></svg>
        <!-- <div class="filled"></div> -->
      </a>
      <!-- <span class="tooltip">Youtube</span> -->
    </li>
    <!-- Facebook -->
    <li class="icon-content">
      <a href="https://www.facebook.com/profile.php?id=61565516438755" target="_blank" data-social="facebook">
        <svg width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#1877F2" d="M15 8a7 7 0 00-7-7 7 7 0 00-1.094 13.915v-4.892H5.13V8h1.777V6.458c0-1.754 1.045-2.724 2.644-2.724.766 0 1.567.137 1.567.137v1.723h-.883c-.87 0-1.14.54-1.14 1.093V8h1.941l-.31 2.023H9.094v4.892A7.001 7.001 0 0015 8z"/><path fill="#ffffff" d="M10.725 10.023L11.035 8H9.094V6.687c0-.553.27-1.093 1.14-1.093h.883V3.87s-.801-.137-1.567-.137c-1.6 0-2.644.97-2.644 2.724V8H5.13v2.023h1.777v4.892a7.037 7.037 0 002.188 0v-4.892h1.63z"/></svg>
        <!-- <div class="filled"></div> -->
      </a>
      <!-- <span class="tooltip">Facebook</span> -->
    </li>
    <!-- TikTok -->
    <li class="icon-content">
      <a href="https://www.tiktok.com/@storagecabinets" target="_blank" data-social="tiktok">
        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2Zm-3.281,8.725h0c-.109.011-.219.016-.328.017A3.571,3.571,0,0,1,14.4,9.129v5.493a4.061,4.061,0,1,1-4.06-4.06c.085,0,.167.008.251.013v2a2.067,2.067,0,1,0-.251,4.119A2.123,2.123,0,0,0,12.5,14.649l.02-9.331h1.914A3.564,3.564,0,0,0,17.719,8.5Z"/></svg>
      </a>
    </li>

    <!-- Instagram -->
    <li class="icon-content">
  <a href="https://www.youtube.com/channel/UCIpghs76jl2HvbHWD3r92cA" target="_blank" data-social="instagram">
    <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve">
      <path fill="rgb(239, 53, 97)" d="M23,0H9C4,0,0,4,0,9v6v8c0,5,4,9,9,9h14c5,0,9-4,9-9v-8V9C32,4,28,0,23,0z"/>
      <circle fill="white" cx="16" cy="16" r="8"/>
      <circle fill="black" cx="25" cy="6" r="2"/>
    </svg>
  </a>
</li>
  </ul>
  <ul class="example-2">
 <!-- visa -->
 <li class="icon-content">
      <a  target="_blank" data-social="visa">
        <svg fill="#142688"  height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"/></svg>
        <div class="filled"></div>
      </a>
      <!-- <span class="tooltip">VISA</span> -->
    </li>
        <!-- visa -->
      <li class="icon-content">
      <a  target="_blank" data-social="visa">
        <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-master">
        <title id="pi-master">Mastercard</title>
        <!-- <path opacity="0.7" d="M35 0h3c1.3 0 3 1.3 3 3v18c0 1.7-1.4 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.4-3 3-3h32z"></path> -->
        <!-- <path fill="#fff" d="M35 1c1.1 0 2 0.9 2 2v18c0 1.1-0.9 2-2 2H3c-1.1 0-2-0.9-2-2V3c0-1.1 0.9-2 2-2h32z"></path> -->
        <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
        <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
        <!-- <path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7 1.8 1.2 3 3.3 3 5.7s-1.2 4.5-3 5.7c1.8-1.2 3-3.3 3-5.7z"></path> -->
      </svg>
      </a>
    </li>
      <!-- paypal -->
      <li class="icon-content">
        <a target="_blank" data-social="paypal">
          <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32" xml:space="preserve">
            <g>
              <path fill="rgb(1, 48, 138)"  d="M12,26H7c-0.8,0-1.6-0.4-2.1-1c-0.5-0.6-0.7-1.4-0.5-2.2L8.7,4l0,0c0.3-1.2,1.3-2,2.6-2h8.6c2.3,0,4.4,1,5.9,2.8
              c1.4,1.8,2,4.1,1.5,6.4c-0.8,4-4.4,6.8-8.5,6.8h-3.2c-0.5,0-1,0.4-1.1,0.9L13,25.2C12.9,25.7,12.5,26,12,26z"/>
            </g>
            <g>
              <path fill="rgb(1, 156, 222)"  d="M29.3,11.3c0,0.1,0,0.2,0,0.3c-1,4.9-5.4,8.4-10.4,8.4h-2.5l-1.4,5.7C14.6,27.1,13.4,28,12,28h-2
              c0.1,0.4,0.2,0.7,0.5,1c0.5,0.6,1.2,0.9,2,0.9H17c0.5,0,0.9-0.3,1-0.7l1.4-5.5c0.1-0.4,0.5-0.6,0.9-0.6h2.9
              c3.7,0,7-2.5,7.7-6C31.3,15,30.7,12.8,29.3,11.3z"/>
            </g>
          </svg>
        </a>
      </li>
  </ul>
        <div class="Contact">
 <p>© Hefei Ningla Trading Co., Ltd.</p>
        <span class="privacy"><a @click="$router.push('/Policy')">Privacy Policy</a> <span class="banquan" style="margin-left: 10px;margin-right: 10px;  color: #979899;">|</span> <a @click="$router.push('/WebsiteTerms')">Terms and Conditions</a></span>
</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomInformation'
}
</script>

<style scoped>
/* 承诺服务 */
.Commitmentservice {
  margin-top: 50px;
  margin-left: 350px;
  width: 1200px;
  margin-bottom: 30px;
  border-top: 2px solid rgba(204, 204, 204);
  border-bottom: 2px solid rgba(204, 204, 204);
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: space-around; /* 在主轴上均匀分布 */
  align-items: center; /* 在交叉轴上居中对齐 */
}

.Commitmentservice ul {
  display: flex; /* 使用 Flexbox 布局 */
  padding: 0; /* 去除默认的 padding */
  margin: 40px 0 0px 0; /* 去除默认的 margin */
}

.Commitmentservice ul li {
  list-style: none;
  padding: 10px;
  width: 260px;
  height: 150px;
  text-align: center; /* 文字居中 */
  border-right: 1px solid rgba(204, 204, 204); /* 添加右边框线 */
}

.Commitmentservice ul li:last-child {
  border-right: none; /* 去掉最后一个项目的右边框 */
}

.commitmentservicetop {
  width: 50px;
  height: 40px;
  margin: 0 auto; /* 水平居中 */
}

.commitmentservicetop img {
  width: 100%;
  height: 100%;
}

.commitmentservicecenter {
  width: 100%;
  height: 50%;
}

.commitmentservicetitle {
  margin-top: 10px; /* 调整标题的顶部间距 */
  font-size: 15px;
  display: block;
  line-height: 20px;
}

.commitmentservicename {
  margin-top: 5px;
  display: block;
  font-size: 13px;
}

/* 网站底部信息 */
.bottomwebsite {
  clear: both;
  margin-left: 350px;
  width: 1200px;
  height: 300px;
}

.bottomwebsiteleft {
  float: left;
  width: 500px;
  height: 100%;
}

.bottomwebsitecenter {
  float: left;
  width: 200px;
  height: 100%;
}

.bottomwebsiteright {
  float: left;
  width: 500px;
  height: 100%;
}

.fuhufologo {
  width: 300px;
  height: 80px;
}

.fuhufologo img {
  width: 100%;
  height: 100%;
}

.telphone {
  line-height: 30px;
  width: 500px;
  height: 30px;
  margin-top: 20px;
  font-size: 15px;
}

.telphone i {
  margin-right: 10px;
  font-size: 18px;
}

.bottomwebsitecenter ul {
  margin-top: 90px;
}

.bottomwebsitecenter ul li {
  font-size: 15px;
  list-style: none;
  margin-bottom: 20px;
}

.bottomwebsitecenter ul li:nth-child(1) {
  font-weight: bold;
  font-size: 15px;
}

.bottomwebsitecenter ul li a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.bottomwebsiteright{
  position: relative;
  /* background-color: #1877F2; */
}
.bottomwebsiteright p {
  margin-top: 100px;
  color: #979899;
  font-size: 12px;
}

.bottomwebsiteright span a {
  text-decoration: none;
  color: #979899;
  font-size: 12px;
  cursor: pointer;
}
.Contact{
  position: absolute;
  top: 80px;
  margin-left: 50px;
  /* margin-top: 80px; */
  /* margin-right: 30px; */
  width: 400px;
  /* height: 100px; */
  /* background-color: aqua; */
}
ul {
  list-style: none;
}

.example-2 {
  /* background-color: #4d4d4d; */
  display: flex;
  justify-content: left;
  align-items: center;
}
.example-2 .icon-content {
  margin: 0 5px;
  position: relative;
}
.example-2 .icon-content .tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 6px 10px;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  transition: all 0.3s ease;
}
.example-2 .icon-content:hover .tooltip {
  opacity: 1;
  visibility: visible;
  top: 60px;
}
.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 20%;
  color: #4d4d4d;
  background-color: #fff;
  /* background-color: #000; */
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 50%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 80px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="Youtube"] .filled,
.example-2 .icon-content a[data-social="Youtube"] ~ .tooltip {
  background-color: red;
}
/* Facebook custom color */
.example-2 .icon-content a[data-social="facebook"] .filled,
.example-2 .icon-content a[data-social="facebook"] ~ .tooltip {
  background-color: #1877F2;
}
/* TikTok custom color */
.example-2 .icon-content a[data-social="tiktok"] .filled,
.example-2 .icon-content a[data-social="tiktok"] ~ .tooltip {
  background-color: #EE1D52;
}
/* Instagram custom color */
.example-2 .icon-content a[data-social="instagram"] .filled,
.example-2 .icon-content a[data-social="instagram"] ~ .tooltip {
  background-color: #C13584;
}
.example-2 .icon-content a[data-social="visa"] .filled,
.example-2 .icon-content a[data-social="visa"] ~ .tooltip {
  background-color: rgba(204, 204, 204);
}

/* 响应式样式 */
@media (max-width: 768px) {
  .Commitmentservice {
    margin-left: 0; /* 在小屏幕上去掉左边距 */
    width: 100%; /* 宽度设为100% */
    margin-top: 30px; /* 调整顶部间距 */
  }

  .Commitmentservice ul {
    position: relative;
    flex-direction: row;
  }

  .Commitmentservice ul li {
    width: 80%; /* 在小屏幕上调整列表项宽度 */
    height: auto; /* 自适应高度 */
    margin-bottom: 20px; /* 添加底部间距 */
  }

  .commitmentservicetop {
    margin-bottom: 80px; /* 重置图标的顶部间距 */
  }

  .commitmentservicetop img {
    width: 40px; /* 调整图片宽度 */
    height: auto; /* 保持纵横比 */
  }

  .commitmentservicetitle {
    font-size: 14px; /* 调整标题字体大小 */
  }

  .commitmentservicename {
    font-size: 12px; /* 调整描述字体大小 */
  }

  .bottomwebsite {
    margin-left: 0; /* 在小屏幕上去掉左边距 */
    width: 100%; /* 宽度设为100% */
    height: auto; /* 高度自适应 */
    display: flex;
    flex-direction: row; /* 使内容垂直排列 */
    align-items: center; /* 中心对齐 */
  }

  .bottomwebsiteleft, .bottomwebsitecenter, .bottomwebsiteright {
    width: 100%; /* 每个部分占满宽度 */
    margin: 10px 0; /* 添加底部间距 */
  }

  .bottomwebsitecenter ul {
    margin-top: 50px; /* 调整列表的顶部间距 */
  }

  .bottomwebsitecenter ul li {
    font-size: 14px; /* 调整中心部分字体大小 */
  }

  .bottomwebsiteright {
    text-align: center; /* 居中对齐 */

  }

  .bottomwebsiteright p, .bottomwebsiteright span a {
    margin-bottom: 80px;
    font-size: 10px; /* 调整版权信息字体大小 */
    text-align: center; /* 居中对齐 */
  }
  .bottomwebsiteright{
    position: relative;
  }
  .bottomwebsiteright span {
    display: flex; /* 使用 Flexbox 进行布局 */
    justify-content: center; /* 居中对齐 */
    margin: 5px 0; /* 添加上下间距 */
    font-size: 14px;
  }

  .Contact {
    position: absolute;
    left: 40px;
  margin: 10px 0; /* Adjust the margin to control spacing above and below */
  padding: 10px; /* Add padding if needed */
}

.Contact p {
  font-size: 12px; /* Adjust the font size if needed */
  text-align: center; /* Center the text if desired */
}

.Contact .privacy {
  margin-top: 5px; /* Reduce the top margin to decrease spacing above the privacy links */
  font-size: 12px; /* Ensure the font size is consistent */
}

.Contact .privacy a {
  color: #979899; /* Link color */
  text-decoration: none; /* Remove underline from links */
  margin: 0 5px; /* Add horizontal spacing between links */
}

.Contact .privacy span {
  color: #979899; /* Separator color */
  margin: 0 5px; /* Add horizontal spacing around the separator */
}

}
</style>
