import request from '@/utils/request'

// 添加购物车
export const PostAddCart = (dynamicGoodsSkuId, goodsNumber) => {
  return request.post('/business/goods-shopping-cart/saveRecord', {
    dynamicGoodsSkuId,
    goodsNumber
  })
}

// 批量添加购物车
export const PostAllAddCart = (addGoodsShoppingCartRequestList) => {
  return request.post('/business/goods-shopping-cart/batchSaveRecord', {
    addGoodsShoppingCartRequestList
  })
}

// 查看购物车列表
export const SelAddCartList = () => {
  return request.post('/business/goods-shopping-cart/listMyShoppingCart')
}

// 查询单个购物车
export const SelCartid = (id) => {
  return request.post('/business/goods-shopping-cart/findOneRecord', {
    id
  })
}

// 批量删除购物车商品
export const DelCartList = (goodsShoppingCartIdList) => {
  return request.post('/business/goods-shopping-cart/clearMyShoppingCart', {
    goodsShoppingCartIdList
  })
}

// 更新商品数量
export const ChangeCartNum = (goodsNumber, id) => {
  return request.post('/business/goods-shopping-cart/updateRecordById', {
    goodsNumber, id
  })
}

// 购物车商品移入收藏
export const MoveCartList = (idList) => {
  return request.post('/business/goods-shopping-cart/cartToCollection', {
    idList
  })
}

// 添加收藏
export const PostCollection = (dynamicGoodsSkuId, goodsNumber) => {
  return request.post('/business/my-collect/saveRecord', {
    dynamicGoodsSkuId,
    goodsNumber
  })
}

// 查看收藏列表
export const SelCollection = () => {
  return request.post('/business/my-collect/myCollection')
}

// 批量删除购物车商品
export const DelCollectionList = (goodsShoppingCartIdList) => {
  return request.post('/business/my-collect/clearMyCollection', {
    goodsShoppingCartIdList
  })
}

// 更新商品数量
export const ChangeCollectionNum = (goodsNumber, id) => {
  return request.post('/business/my-collect/updateRecordById', {
    goodsNumber, id
  })
}

// 收藏商品移入购物车
export const MoveCollectionList = (idList) => {
  return request.post('/business/my-collect/collectionToCart', {
    idList
  })
}

// 批量查询商品
export const Selproducts = (id) => {
  return request.post('/business/dynamic-goods-sku/listRecord', {
    skuProperties: {
      id
    }
  })
}

// 创建订单
export const Createorder = (dynamicGoodsList, telephone, userAddressId) => {
  return request.post('/business/order/saveRecord', {
    dynamicGoodsList,
    telephone,
    userAddressId,
    payWay: ''
  })
}

// 单个查询订单
export const Selorder = (id) => {
  return request.post('/business/order/findOneRecord', {
    id
  })
}

// 我的订单
export const getmyOrder = () => {
  return request.post('/business/order/myOrder', {
    id: ''
  })
}
