<template>
  <div class="address-management">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h2>Address Management</h2>

    <!-- New Address Form -->
    <div class="form-container">
      <input v-model="newResidence" placeholder="Enter residence address" />
      <input v-model="newShipping" placeholder="Enter shipping address" />
      <input v-model="newZipCode" placeholder="Enter ZIP Code" />
      <input v-model="newRecipientName" placeholder="Enter recipient name" />
      <input v-model="newTelephone" placeholder="Enter telephone" /> <!-- Telephone input -->
      <button class="btn" @click="addAddress">Add Addresses</button>
    </div>

    <!-- Address List -->
    <div v-for="(addressPair, index) in paginatedAddresses" :key="index" class="address-item">
      <el-card shadow="always">
        <div class="card-content">
          <p class="address-text">
            <strong>Residence:</strong> {{ addressPair.city }}
          </p>
          <p class="address-text">
            <strong>Shipping:</strong> {{ addressPair.address }}
          </p>
          <p class="address-text">
            <strong>ZIP Code:</strong> {{ addressPair.zipCode }}
          </p>
          <p class="address-text">
            <strong>Recipient:</strong> {{ addressPair.recipient }}
          </p>
          <p class="address-text">
            <strong>Telephone:</strong> {{ addressPair.telephone }} <!-- Display telephone -->
          </p>
          <div class="actions">
            <button class="btn" @click="editAddress(addressPair.id)">Edit</button>
            <button class="btn" @click="deleteAddress(addressPair.id)">Delete</button>
          </div>
        </div>
      </el-card>
    </div>

    <!-- Pagination -->
    <el-pagination
      v-if="addresses.length > pageSize"
      background
      layout="prev, pager, next"
      :total="addresses.length"
      :page-size="pageSize"
      @current-change="handlePageChange">
    </el-pagination>

    <!-- Edit Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h3>Edit Address</h3>
        <label>
          Residence:
          <input v-model="editedAddressCity" />
        </label>
        <label>
          Shipping:
          <input v-model="editedAddressAddress" />
        </label>
        <label>
          ZIP Code:
          <input v-model="editedZipCode" />
        </label>
        <label>
          Recipient Name:
          <input v-model="editedRecipientName" />
        </label>
        <label>
          Telephone:
          <input v-model="editedTelephone" /> <!-- Edit telephone -->
        </label>
        <button class="btn1" @click="confirmEdit">Confirm</button>
        <button class="btn1" @click="cancelEdit">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Postaddress, Seladdress, SelOneaddress, ChangeAddress, DelAddress } from '@/api/address/address'
export default {
  name: 'AddressIndex',
  data () {
    return {
      newResidence: '',
      newShipping: '',
      newZipCode: '',
      newRecipientName: '',
      newTelephone: '', // New telephone input
      addresses: [],
      showModal: false,
      editedAddressId: '',
      editedAddressCity: '',
      editedAddressAddress: '',
      editedZipCode: '',
      editedRecipientName: '',
      editedTelephone: '', // Edit telephone input
      currentPage: 1,
      pageSize: 3
    }
  },
  computed: {
    paginatedAddresses () {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      return this.addresses.slice(start, end)
    }
  },
  created () {
    this.Seladdress()
  },
  methods: {
    async addAddress () {
      if (this.newResidence && this.newShipping && this.newZipCode && this.newRecipientName && this.newTelephone) {
        console.log(this.newResidence, this.newShipping, this.newZipCode, this.newRecipientName, this.newTelephone)
        const res = await Postaddress(this.newShipping, this.newResidence, this.newZipCode, this.newTelephone, this.newRecipientName)
        if (res.code !== 200) {
          return this.$message.error('Failed to add address, please try again')
        } else {
          this.$message.success('The address was added successfully')
          this.Seladdress()
          this.newResidence = ''
          this.newShipping = ''
          this.newZipCode = ''
          this.newRecipientName = ''
          this.newTelephone = ''
        }
      }
    },
    async Seladdress () {
      const res = await Seladdress()
      console.log(res)
      this.addresses = res.data
    },
    async deleteAddress (id) {
      const res = await DelAddress(id)
      if (res.code !== 200) {
        return this.$message.error('The address deletion failed, please try again')
      } else {
        this.$message.success('The address was deleted')
        this.Seladdress()
      }
    },
    async editAddress (id) {
      const res = await SelOneaddress(id)
      console.log(res)

      this.editedAddressId = res.data.id
      this.editedAddressCity = res.data.city
      this.editedAddressAddress = res.data.address
      this.editedZipCode = res.data.zipCode
      this.editedRecipientName = res.data.recipient
      this.editedTelephone = res.data.telephone // Set telephone for editing
      this.showModal = true
    },
    async confirmEdit () {
      const res = await ChangeAddress(this.editedAddressId, this.editedAddressAddress, this.editedAddressCity, this.editedZipCode, this.editedTelephone, this.editedRecipientName)
      console.log(res)
      if (res.code !== 200) {
        this.showModal = false
        return this.$message.error('Failed to modify the address, try again')
      } else {
        this.$message.success('The address was modified')
        this.Seladdress()
        this.showModal = false
      }
    },
    cancelEdit () {
      this.showModal = false
    },
    handlePageChange (page) {
      this.currentPage = page
    }
  }
}

</script>

<style scoped>
.address-management {
  font-family: Arial, sans-serif;
  color: #000;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

h2 {
  text-align: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

input {
  padding: 8px;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
}

.btn {
  padding: 8px 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn1 {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #333;
}

.address-item {
  margin-bottom: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.address-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.el-pagination {
  margin-top: 20px;
  text-align: center;
}
/* Media Queries for small screens */
@media (max-width: 600px) {
  .address-management {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  .form-container {
    gap: 8px;
  }

  input {
    font-size: 14px;
    padding: 6px;
  }

  .btn, .btn1 {
    font-size: 14px;
    padding: 6px 10px;
  }

  .address-text {
    font-size: 14px;
  }

  .modal-content {
    padding: 15px;
  }
  .modal {
    font-size: 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  font-size: 16px;
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
}
</style>
