<template>
  <div class="login">
    <!-- 头部 -->
    <div class="logintitle">
      <div class="loginlogo">
        <img src="@/img/icon/logo.jpg" alt="">
      </div>
    </div>
    <!-- 内容 -->
    <div class="registerbanner">
      <h1>Sign up as a buyer</h1>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;">*</span> Buyer Name</span>
        </div>
        <div class="input-group">
          <el-input v-model="inputFirstName" placeholder="First Name" class="input-field"></el-input>
          <el-input v-model="inputLastName" placeholder="Last Name" class="input-field"></el-input>
        </div>
      </div>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;">*</span> ID</span>
        </div>
        <div class="input-group">
          <el-input v-model="Username" placeholder="Username" class="input-field"></el-input>
        </div>
      </div>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;">*</span> Login Email</span>
        </div>
        <div class="input-group">
          <el-input v-model="Email" placeholder="Please enter your login email" class="input-field"></el-input>
        </div>
      </div>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;">*</span> Password</span>
        </div>
        <div class="input-group">
          <el-input v-model="Password" placeholder="Please enter your login password" class="input-field" show-password></el-input>
        </div>
      </div>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;">*</span> Confirm password</span>
        </div>
        <div class="input-group">
          <el-input v-model="Confirmpassword" placeholder="Please enter your login password again" class="input-field" show-password></el-input>
        </div>
      </div>
      <div class="registerform">
        <div class="registerinput">
          <span class="name"><span style="color: red;margin-right: 5px;">*</span>Phone Number</span>
        </div>
        <div class="input-group">
          <el-form>
            <el-form-item label="" prop="phone" style="width: 100%; height: 41px;">
              <el-phone-number-input v-model="phone" :numberPlaceholder="'Phone Number'" :codePlaceHolder="'Select Country'"></el-phone-number-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="registerform checkbox-container">
        <el-checkbox v-model="checked" class="checkbox"></el-checkbox>
        <span class="checkbox-text">You are creating a FUHUFO buyer account and accept the <a style="color: blue;  cursor: pointer;"  @click="$router.push('/WebsiteTerms')">Terms and Conditions</a> of FUHUFO</span>
      </div>
      <div class="registerform" v-if="checked">
        <el-button type="primary" class="signup-button" @click="getreagisterForm()">Sign Up</el-button>
      </div>
      <div class="registerform" v-else>
        <el-button type="primary" class="signup-button" disabled style="background-color: rgba(193, 193, 193);">Sign Up</el-button>
      </div>
      <div class="registerform">
        <div class="backlogin"> Already have a Buyer account? <a @click="$router.push('/login')">Login Now</a></div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="CopyrightInformation">
      <p>© 2019-2024 GigaCloud Technology (Hong Kong) Limited. All Rights Reserved</p>
      <p>
        <a @click="$router.push('/Policy')">Privacy Policy</a>
        <span class="separator">|</span>
        <a  @click="$router.push('/WebsiteTerms')">Terms and Conditions</a>
      </p>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { ElPhoneNumberInput } from 'el-phone-number-input'
import { getregister } from '@/api/login/register'
export default {
  name: 'RegisterIndex',
  components: {
    ElPhoneNumberInput
  },
  data () {
    return {
      phone: {
        callingCode: '',
        phoneNumber: ''
      },
      inputFirstName: '',
      inputLastName: '',
      Username: '',
      Email: '',
      Password: '',
      Confirmpassword: '',
      checked: false
    }
  },
  methods: {
    async getreagisterForm () {
      // console.log(this.phone.countryCode)
      if (this.Password !== this.Confirmpassword) {
        return this.$message.warning('Password and Confirm Password do not match')
      }
      const USname = this.inputLastName + this.inputFirstName
      const res = await getregister(this.phone.callingCode, this.phone.countryCode, this.Email, this.phone.phoneNumber, USname, md5(this.Password))
      if (res.code !== 200) {
        return this.$message.warning(res.msg)
      } else {
        this.$message.success('The account is successfully registered, go to Login')
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
/* 版心 */
.w {
  width: 1800px;
  margin: auto;
}

/* 头部 */
.logintitle {
  height: 51px;
}
.loginlogo {
  width: 206px;
  height: 100%;
  margin-left: 50px;
}
.loginlogo img {
  width: 100%;
  height: 100%;
}

/* 内容区域 */
.registerbanner {
  text-align: center;
  border-top: 1px solid rgba(204, 204, 204);
  border-bottom: 1px solid rgba(204, 204, 204);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* 自适应宽度 */
  max-width: 1910px; /* 最大宽度 */
  height: auto; /* 自适应高度 */
  padding: 20px; /* 添加内边距 */
}
.registerbanner h1{
  font-size: 35px;
}
/* 注册表单 */
.registerform {
  width: 550px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

/* 注册输入框 */
.registerinput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.registerinput .name {
  font-size: 18px;
  display: block;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
}

/* 输入框组 */
.input-group {
  display: flex;
  gap: 20px;
}

.input-field {
  flex: 1;
  width: 100%;
}

/* 单选框和文本容器 */
.checkbox-container {
  display: flex;
  align-items: top;
  margin-top: 10px;
}

.checkbox {
  margin-right: 10px;
}

/* 文本样式 */
.checkbox-text {
  color: #333;
  font-size: 14px;
  max-width: calc(100% - 30px);
  overflow-wrap: break-word;
}

/* 按钮样式 */
.signup-button {
  width: 100%;
  height: 41px;
  font-weight: bold;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.signup-button:hover {
  background-color: rgba(16, 50, 93);
}

/* 底部信息 */
.CopyrightInformation {
  width: 100%;
  height: 90px;
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.CopyrightInformation p {
  margin: 0;
  font-size: 14px;
  color: #979899;
  margin-bottom: 10px;
}

.CopyrightInformation a {
  color: #979899;
  text-decoration: none;
  cursor: pointer;
}

.CopyrightInformation a:hover {
  text-decoration: underline;
}

.separator {
  margin: 0 10px;
  color: #979899;
}

.backlogin {
  font-size: 14px;
}

.backlogin a {
  cursor: pointer;
  text-decoration: none;
  color: blue;
  margin-left: 10px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .registerbanner h1{
  font-size: 35px;
}
  .registerbanner {
    width: 100%; /* 自适应宽度 */
    padding: 10px; /* 减少内边距 */
  }

  .registerform {
    width: 90%; /* 确保在移动设备上占据90%的宽度 */
    padding: 5px; /* 减少内边距 */
  }

  .input-group {
    flex-direction: column; /* 垂直排列输入框 */
  }

  .input-field {
    width: 100%; /* 输入框自适应宽度 */
    margin-bottom: 10px; /* 输入框之间的间距 */
  }

  .signup-button {
    height: 36px; /* 在移动端适当缩小按钮高度 */
  }

  .CopyrightInformation {
    font-size: 12px; /* 减少版权信息字体大小 */
  }
}
</style>
