<template>
  <div class="review-order-page">
    <!-- 返回箭头 -->
    <div class="back-arrow" @click="goBack">
      <el-button class="black-button" icon="el-icon-arrow-left">Back</el-button>
    </div>

    <!-- 订单列表标题 -->
    <h3 class="order-list-title">Order List</h3>

    <!-- 订单信息展示 -->
    <div class="order-details" v-for="(item, index) in orderItems" :key="index">
      <el-card class="order-item">
        <img :src="item.productImage" alt="Product Image" class="product-image" />
        <div class="product-info">
          <p class="product-name">{{ item.productName }}</p>
          <p class="product-price">
            Price: <span class="price-value">${{ item.productPrice }}</span>
          </p>
          <p class="product-quantity">Quantity: {{ item.quantity }}</p>
        </div>
      </el-card>
    </div>

    <!-- 总价展示 -->
    <div class="total-amount">
      <p>Total Payment: ${{ totalPrice }}</p>
    </div>

    <!-- 页面标题 -->
    <h2 class="page-title">Please Submit Your Review Below</h2>

    <!-- 上传商品图片和评价文本 -->
    <div class="review-section">
      <div class="upload-container">
        <span>Image upload:</span>
        <el-upload
          class="upload-demo"
          action="YOUR_UPLOAD_API_URL"
          :file-list="fileList"
          list-type="picture"
          :before-upload="beforeUpload"
          :http-request="customRequest"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
        >
          <el-button class="black-button" size="small" type="primary">Click Upload</el-button>
        </el-upload>
      </div>
      <span>Product reviews:</span>
      <el-input
        type="textarea"
        v-model="reviewText"
        placeholder="Please enter your review..."
        class="review-text"
      ></el-input>
    </div>

    <!-- 提交评价按钮 -->
    <el-button class="black-button" type="primary" @click="submitReview">Submit Review</el-button>
  </div>
</template>

<script>
import { getuploadfile } from '@/api/login/register'

export default {
  data () {
    return {
      id: '',
      orderItems: [],
      reviewText: '',
      uploadedImages: [],
      fileList: [],
      totalPrice: 0
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.fetchOrderDetails()
  },
  methods: {
    fetchOrderDetails () {
      this.orderItems = [
        { productImage: require('@/img/Productdetail/bann1.png'), productName: 'Product A', productPrice: 100, quantity: 2, totalPrice: 200 },
        { productImage: require('@/img/Productdetail/bann1.png'), productName: 'Product B', productPrice: 150, quantity: 1, totalPrice: 150 }
      ]
      this.calculateTotalPrice()
    },
    calculateTotalPrice () {
      this.totalPrice = this.orderItems.reduce((sum, item) => sum + item.totalPrice, 0)
    },
    goBack () {
      this.$router.go(-1)
    },
    beforeUpload (file) {
    },
    customRequest ({ file, onSuccess, onError }) {
      const formData = new FormData()
      formData.append('file', file)

      getuploadfile(formData)
        .then(response => {
          onSuccess(response.data, file)
          this.uploadedImages.push({ url: response.data.url, fileName: file.name })
        })
        .catch(error => {
          onError(error)
          this.$message.error('Upload failed')
        })
    },
    handlePreview (file) {
      console.log(file)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
      this.uploadedImages = this.uploadedImages.filter(image => image.url !== file.url)
    },
    submitReview () {
      const reviewData = {
        id: this.id,
        reviewText: this.reviewText,
        uploadedImages: this.uploadedImages.map(image => image.url)
      }
      console.log('Submit Review:', reviewData)
    }
  }
}
</script>

<style scoped>
.review-order-page {
  padding: 20px;
}

.back-arrow {
  margin-bottom: 20px;
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.order-list-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.order-details {
  margin-bottom: 20px;
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price-value {
  color: orange;
}

.total-amount {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}

.review-section {
  margin: 20px 0;
}

.upload-container {
  display: flex;
  align-items: flex-start;
}

.upload-container span {
  margin-right: 30px;
  margin-bottom: 40px;
}

.upload-demo {
  margin-right: 20px;
}

.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.uploaded-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
}

.review-text {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

/* 按钮样式 */
.black-button {
  background-color: black;
  border-color: black;
  color: white;
}

.black-button:hover {
  background-color: #333;
  border-color: #333;
}

.black-button:focus, .black-button:active {
  box-shadow: none;
}
/* 按钮样式 */
.black-button {
  background-color: black;
  border-color: black;
  color: white;
}

/* 悬停状态 */
.black-button:hover {
  background-color: #333;
  border-color: #333;
}

/* 按下状态 */
.black-button:active {
  background-color: #444;
  border-color: #444;
}

/* 聚焦状态 */
.black-button:focus {
  box-shadow: none; /* 去除默认的焦点阴影 */
}

/* 禁用状态 */
.black-button[disabled] {
  background-color: #666;
  border-color: #666;
  color: #999;
}

/* 媒体查询 */
@media (max-width: 768px) {
  .product-image {
    width: 60px;
    height: 60px;
  }

  .product-info {
    font-size: 14px;
  }

  .review-text {
    font-size: 14px;
  }

  .el-button {
    width: 100%;
  }

  .total-amount {
    font-size: 16px;
  }
  .review-section  span {
    font-size: 22px;
}
}
</style>
