import request from '@/utils/request'

// 关于我们列表
export const getaboutuslist = () => {
  return request.post('/business/about-us/listRecord', {
    id: ''
  })
}

// 上传联系我们
export const PostContactus = (name, email, telephone, subject, message) => {
  return request.post('/business/contact-us/saveRecord', {
    name, email, telephone, subject, message
  })
}
