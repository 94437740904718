import request from '@/utils/request'

// 获取博客列表
export const getbloglist = () => {
  return request.post('/business/blog/listRecord', {
    id: ''
  })
}

// 单个查询博客
export const findblogOne = (id) => {
  return request.post('/business/blog/findOneRecord', {
    id
  })
}
