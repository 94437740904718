import request from '@/utils/request'

// 商品点击次数
export const ProductClicks = (businessId) => {
  return request.post('/business/hot-total/clickTotal', {
    businessId,
    type: 2
  })
}

// 主链接点击次数
export const MainLinkClicks = () => {
  return request.post('/business/hot-total/clickTotal', {
    businessId: 'https://shop.fohufo.com/',
    type: 1
  })
}

// 次链接点击次数查询
export const LinkClicks = (businessId) => {
  return request.post('/business/hot-total/clickTotal', {
    businessId,
    type: 1
  })
}
