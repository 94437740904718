<template>
  <div class="contact-us-container">
    <HeaderInformation></HeaderInformation>
  <div class="contactusContent">
    <el-row justify="center" align="middle">
      <el-col :span="12" class="contact-info">
        <h2>Contact Us</h2>
        <p class="info-text">
          Located in our Hefei, Anhui headquarters, our expert Customer Experience Specialists
          are ready to assist you with everything from design ideas and product recommendations to delivery details and service questions.
        </p>
        <p class="info-text"><strong>Monday – Friday:</strong> 8 a.m. to 7 p.m. (CT)</p>
        <p class="info-text"><strong>Saturday – Sunday:</strong> 10 a.m. to 5 p.m. (CT)</p>
        <div class="contact-details">
          <el-row :gutter="20" class="contact-row">
            <el-col :span="11" class="contact-item">
              <p>📞 Call Us</p>
              <p>+1 716-427-4450 (9AM-5PM) PST</p>
            </el-col>
            <div class="contact-divider"></div>
            <el-col :span="11" class="contact-item">
              <p>📧 Email</p>
              <p>modernfurniture1999@bestfurnitureland.com</p>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12" class="contact-image">
        <img src="@/img/contactus/contactus.png" alt="Contact Us Image">
      </el-col>
    </el-row>
    <el-form :model="form" :rules="rules" ref="contactForm" @submit.native.prevent="onSubmit" class="contact-form">
      <el-form-item label="Name" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Email" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="Phone (optional)" :label-width="formLabelWidth" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="Subject" :label-width="formLabelWidth" prop="subject">
        <el-select v-model="form.subject" placeholder="Select a subject">
          <el-option label="General Inquiries" value="General Inquiries"></el-option>
          <el-option label="Design or Product Help" value="Design or Product Help"></el-option>
          <el-option label="Your Order & Delivery" value="Your Order & Delivery"></el-option>
          <el-option label="Returns & Exchanges" value="Returns & Exchanges"></el-option>
          <el-option label="Business Orders" value="Business Orders"></el-option>
          <el-option label="Media Inquiries" value="Media Inquiries"></el-option>
          <el-option label="Vendor Inquiries" value="Vendor Inquiries"></el-option>
          <el-option label="Careers" value="Careers"></el-option>
          <el-option label="Website Feedback" value="Website Feedback"></el-option>
          <el-option label="Website Technical Issues" value="Website Technical Issues"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Your Message" :label-width="formLabelWidth" prop="message">
        <el-input type="textarea" v-model="form.message" maxlength="200"></el-input>
        <span>{{ 200 - form.message.length }} characters remain</span>
      </el-form-item>
      <el-form-item class="checkbox">
        <el-checkbox v-model="form.sendCopy">Send me a copy of this email.</el-checkbox>
        <el-checkbox v-model="form.subscribe">Please send me email updates about products and special events.</el-checkbox>
      </el-form-item>
    </el-form>
    <el-button type="danger" class="subBtn" @click="onSubmit">Submit</el-button>
  </div>
    <BottomInformation></BottomInformation>
  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import BottomInformation from '@/components/BottomInformation.vue'
import { PostContactus } from '@/api/Contactus/contactus'
export default {
  name: 'ContantUsIndex',
  components: {
    HeaderInformation,
    BottomInformation
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        sendCopy: false,
        subscribe: false
      },
      formLabelWidth: '120px',
      rules: {
        name: [
          { required: true, message: 'Name is required', trigger: 'blur' }
        ],
        email: [
          { required: true, trigger: 'blur' }
        ],
        phone: [
          { trigger: 'blur' }
        ],
        subject: [
          { required: true, message: 'Subject is required', trigger: 'change' }
        ],
        message: [
          { required: true, message: 'Message is required', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      console.log('Form submitted:', this.form)
      // 在这里添加提交逻辑，例如通过API发送数据
      this.$refs.contactForm.validate(async (valid) => {
        if (valid) {
          // console.log('Form submitted:', this.form)
          const res = await PostContactus(this.form.name, this.form.email, this.form.phone, this.form.subject, this.form.message)
          // console.log(res)
          if (res.code !== 200) {
            return this.$message.error('Failed to send a message, please try again')
          } else {
            this.$message.success('The message was successfully sent')
            this.form = {
              name: '',
              email: '',
              phone: '',
              subject: '',
              message: '',
              sendCopy: false,
              subscribe: false
            }
          }
          // 在这里添加提交逻辑，例如通过API发送数据
        } else {
          // console.log('Validation failed')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.contact-us-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* padding: 40px 20px; */
}
.contactusContent{
  margin-top: 20px;
  margin-left: 300px;
}
.checkbox{
  margin-left: 80px;
}
.subBtn{
  margin-left: 80px;
  width: 150px;
}
.contact-info {
  padding: 20px;
}

.contact-image img {
  max-width: 100%;
  border-radius: 8px;
}

.contact-details {
  margin-top: 20px;
}

.contact-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-item p {
  margin: 0;
}

.contact-divider {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 10px;
}

.contact-form {
  margin-top: 40px;
  max-width: 600px;
  width: 100%;
}

/* Media Queries */
@media (max-width: 768px) {
  .contact-us-container {
    padding: 20px;
  }
  .contactusContent{
  margin-top: 20px;
  margin-left: 00px;
}
  .el-row {
    flex-direction: column;
  }

  .contact-info, .contact-image {
    text-align: center;
  }

  .info-text, .contact-item p, .el-form-item__label, .el-input__inner {
    font-size: 14px;
  }

  .contact-form {
    max-width: 100%;
  }

  h2 {
    font-size: 20px;
  }
  .checkbox{
  margin-left: 700px;
}
.subBtn{
  margin-left: 750px;
  width: 300px;
}
}

@media (max-width: 480px) {
  .info-text, .contact-item p, .el-form-item__label, .el-input__inner {
    font-size: 12px;
  }

  h2 {
    font-size: 18px;
  }
}
</style>
