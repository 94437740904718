<template>
  <div class="userinfo">
    <!-- Back to previous page -->
    <div class="userinfo-header">
      <el-page-header @back="goBack" title="Return"></el-page-header>
      <!-- Logout Button in the top right corner -->
      <el-button type="danger" @click="confirmLogout" class="logout-button">Sign out</el-button>
    </div>
    <div class="user-title">User Information</div>
    <div class="userinfo-content">
      <el-descriptions title="" :column="1" class="user-descriptions">
        <el-descriptions-item label="Avatar">
          <div class="avatar-container">
            <el-avatar :src="user.avatar" size="large" class="avatar"></el-avatar>
            <el-upload
              class="avatar-uploader custom-upload"
              action=""
              :show-file-list="false"
              :on-change="handleAvatarChange"
              :before-upload="beforeAvatarUpload">
              <button size="small" class="upload-button">Change Avatar</button>
            </el-upload>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="Username">{{ user.username }}</el-descriptions-item>
        <el-descriptions-item label="Email">{{ user.email }}</el-descriptions-item>
        <el-descriptions-item label="Phone">{{ user.phone }}</el-descriptions-item>
        <!-- <el-descriptions-item label="Residence">{{ user.residence }}</el-descriptions-item>
        <el-descriptions-item label="Address">{{ user.address }}</el-descriptions-item> -->
        <el-descriptions-item label="Nationality"><el-tag class="custom-nationality-tag">{{ user.nationality ? user.nationality :'NULL' }}</el-tag></el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { getuserinfo, changeuserinfo, getuploadfile } from '@/api/login/register'
import { MessageBox } from 'element-ui'
import store from '@/store'

export default {
  name: 'UserInfoIndex',
  data () {
    return {
      user: {
        avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        username: 'kooriookami',
        email: 'kooriookami@example.com',
        phone: '18100000000',
        residence: 'null',
        nationality: 'USA',
        address: 'null'
      }
    }
  },
  created () {
    this.getuserinfo()
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async handleAvatarChange (event) {
      const file = event.raw
      const formData = new FormData()
      formData.append('file', file)

      try {
        // Step 1: Upload the image to the server and get the URL
        const uploadResponse = await getuploadfile(formData)
        const uploadedUrl = uploadResponse.data.url

        // Step 2: Use the URL to update the user's avatar
        await changeuserinfo(uploadedUrl)

        // Step 3: Update the avatar in the UI
        this.user.avatar = uploadedUrl
        this.$message.success('Avatar updated successfully!')
      } catch (error) {
        this.$message.error('Failed to update avatar.')
        console.error('Error updating avatar:', error)
      }
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG && !isPNG) {
        this.$message.error('Avatar must be a JPG or PNG file!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar size must be less than 2MB!')
      }
      return (isJPG || isPNG) && isLt2M
    },
    async getuserinfo () {
      const res = await getuserinfo()
      console.log(res.data)
      const data = res.data
      this.user.username = data.userName
      this.user.avatar = data.photo
      this.user.email = data.email
      this.user.phone = data.telephone
      this.user.nationality = data.country
    },
    confirmLogout () {
      MessageBox.confirm('Are you sure you want to log out?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        // Perform logout logic here
        console.log('User logged out.')
        store.state.user.token = ''
        this.$message.success('Logout successful')
        this.$router.push('/login')
      }).catch(() => {
        console.log('Logout canceled.')
      })
    }
  }
}
</script>

<style scoped>
.userinfo-header {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between; /* Align header items */
  align-items: center; /* Center items vertically */
}

.userinfo-content {
  width: 80%;
  max-width: 1800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-title {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
}

.user-descriptions .el-descriptions-item {
  margin-bottom: 20px;
}

.avatar-container {
  display: flex;
  align-items: center;
}

.avatar {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.avatar-uploader {
  margin-top: 0px;
}

/* Custom styles for the upload button */
.custom-upload .el-upload {
  display: inline-block;
}

.upload-button {
  height: 30px;
  border-radius: 10px;
  background-color: #f0f0f0; /* Normal background color */
  color: black; /* Normal text color */
  border: 1px solid #dcdcdc; /* Normal border color */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  font-size: 14px; /* Default font size */
}

.upload-button:hover {
  background-color: black; /* Background color on hover */
  color: white; /* Text color on hover */
}

.upload-button:active {
  background-color: #333; /* Darker background color on click */
  color: white; /* Text color on click */
}

.custom-nationality-tag {
  background-color: black; /* 背景色为黑色 */
  color: white; /* 文字颜色为白色 */
}

.logout-button {
  margin-right: 170px; /* Align to the right */
}

/* 响应式样式 */
@media (max-width: 768px) {
  .userinfo-content {
    width: 90%; /* Increase width for smaller screens */
    padding: 10px; /* Reduce padding */
  }

  .user-title {
    font-size: 18px; /* Smaller font size for title */
  }

  .upload-button {
    font-size: 12px; /* Smaller font size for button */
  }

  .avatar {
    width: 80px; /* Smaller avatar size */
    height: 80px; /* Smaller avatar size */
    margin-right: 10px; /* Reduce margin */
  }

  .logout-button {
    margin-right: 20px; /* Adjust margin for smaller screens */
  }
}
</style>
