
  <template>
    <div class="Scenography">
      <HeaderInformation></HeaderInformation>
      <el-row class="container">
        <el-col :span="12" class="left-panel">
          <!-- <div class="modeling-image">
            <img :src="modelImg" alt="" class="model-img" />
            <img v-for="(item, index) in selectedItems" :key="index" :src="item.imgurl" class="replace-marker" />
          </div> -->
          <!-- <button @click="showSelectedItems" class="detailesbtn">View checked</button> -->
           <div class="modeling-image">
            <div id="viewer" class="model-img"></div>
           </div>
        </el-col>
        <el-col :span="12" class="right-panel">
  <div class="tab-container">
    <div class="tab-header">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-title"
        :class="{ active: tabIndex === index }"
        @click="tabIndex = index; currentPage = 1"
      >
        {{ tab.label }}
      </div>
    </div>
    <div class="item-list">
      <div
        v-for="item in paginatedItems"
        :key="item.id"
        class="item"
        :class="{ selected: selectedItem[tabs[tabIndex].label] === item.id }"
        @click="handleItemSelected(item, tabs[tabIndex].label)"
      >
        <img :src="item.showimgurl || item.imgurl" alt="item.name" />
        <span>{{ item.name }}</span>
        <button v-if="selectedItem[tabs[tabIndex].label] === item.id"
        @click.stop="viewDetails(item)" class="detailesbtn1" style="display: block;" >View details</button>
      </div>
    </div>
    <!-- 分页控件 -->
    <div class="pagination-controls">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</el-col>
        <!-- 查看选择产品 -->
        <el-dialog title="The item I selected" :visible.sync="selectedItemsModalVisible">
          <div class="selected-items-grid">
            <div v-for="item in selectedItems" :key="item.id" class="grid-item">
              <img :src="item.showimgurl || item.imgurl" alt="item.name" />
              <span>{{ item.name }}</span>
            </div>
          </div>
          <span slot="footer">
            <el-button @click="addselectedToCart" type="danger">Add custom lists</el-button>
            <!-- <el-button @click="selectedItemsModalVisible = false">Shut down</el-button> -->
            <button @click="selectedItemsModalVisible = false" class="detailesbtn">Shut down</button>
          </span>
        </el-dialog>
        <!-- 查看产品详情 -->
        <el-drawer title="Product Details" :visible.sync="drawerVisible" direction="rtl" size="30%">
          <div v-if="selectedProduct">
            <img :src="selectedProduct.showimgurl || selectedProduct.imgurl" alt="product image" class="drawer-product-image" />
            <h3 class="drawer-product-name">{{ selectedProduct.SpecificName }}</h3>

            <div class="drawer-product-stock">
              <h4>Inventory information</h4>
              <ul>
                <li>
                  <span>USAstock:{{selectedProduct.stock}}</span>
                </li>
              </ul>
            </div>

            <div class="drawer-buttons">
              <el-button type="danger" @click="addToCart(selectedProduct)">Add custom lists</el-button>
              <!-- <el-button type="default" @click="$router.push(`/Productdetails/${ selectedProduct.id}`)">view details</el-button> -->
            <button class="detailesbtn" @click="ProductClicks" style="width: 50%;">View details</button>

            </div>
            <p class="drawer-product-description">{{ selectedProduct.description }}</p>
          </div>
        </el-drawer>
      </el-row>
      <div class="bottom-info">
        You can view matching effects by clicking on the product catalog on the right.
      </div>
    </div>
  </template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import { getclassiftimg, getproductsclassifytab } from '@/api/classify/classify'
import { PostAddCart, PostAllAddCart } from '@/api/cart/cart'
import { ProductClicks } from '@/api/hotspot/hotspot'
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
export default {
  name: 'ScenographyIndex',
  components: {
    HeaderInformation
  },
  data () {
    return {
      id: '',
      modelImg: '',
      tabIndex: 0,
      selectedItems: [],
      selectedItem: {},
      selectedItemsModalVisible: false,
      drawerVisible: false,
      selectedProduct: null,
      currentPage: 1,
      itemsPerPage: 4, // 每页显示的项目数量
      tabs: []
    }
  },
  computed: {
    anyItemSelected () {
      return Object.values(this.selectedItem).some(itemId => itemId !== undefined)
    },
    paginatedItems () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.tabs[this.tabIndex]?.items.slice(start, end) || []
    },
    totalPages () {
      return Math.ceil((this.tabs[this.tabIndex]?.items.length || 0) / this.itemsPerPage)
    }
  },
  created () {
    this.id = this.$route.params.id
    this.getclassiftimg()
    this.getproductsclassifytab()
  },
  mounted () {
    this.modelImg = require('@/img/VR/4.jpg')
    // 初始化 Photo Sphere Viewer
    this.viewer = new Viewer({
      panorama: this.modelImg, // 360 图像
      container: document.getElementById('viewer'), // 容器
      navbar: true, // 是否显示控制导航
      loadingImg: 'loading-spinner.gif' // 可选的加载图片
    })
  },

  beforeDestroy () {
    // 清理 viewer
    if (this.viewer) {
      this.viewer.destroy()
    }
  },
  methods: {
    // 获取左侧大图
    async getclassiftimg () {
      const res = await getclassiftimg(this.id)
      this.modelImg = res.data.detailBlankImageUrl
      this.viewer.setPanorama(this.modelImg)
    },
    // 获取右侧商品
    async getproductsclassifytab () {
      const res = await getproductsclassifytab(this.id)
      this.tabs = res.data.map(tab => {
        return {
          label: tab.baseGoodsName,
          items: tab.skuDocuments.map(doc => {
            const sku = doc.skuProperties
            console.log(sku)
            return {
              id: sku.id,
              name: sku.productsName,
              SpecificName: sku.productsName,
              imgurl: sku.baseImageUrl,
              showimgurl: sku.mainImageUrl,
              stock: 1000,
              description: sku.productsText,
              skuData: sku.skuData
            }
          })
        }
      })

      // 默认选中每个tab栏的第一个项目
      this.tabs.forEach(tab => {
        if (tab.items.length > 0) {
          this.selectedItem[tab.label] = tab.items[0].id
        }
      })

      // 更新 selectedItems 列表
      this.updateSelectedItems()
    },
    handleItemSelected (item, tabLabel) {
      if (this.selectedItem[tabLabel] === item.id) return
      this.selectedItem[tabLabel] = item.id
      this.selectedItems = Object.entries(this.selectedItem).map(([key, value]) => {
        const selectedTab = this.tabs.find(tab => tab.label === key)
        return selectedTab ? selectedTab.items.find(i => i.id === value) : null
      }).filter(item => item)
    },
    updateSelectedItems () {
      this.selectedItems = Object.entries(this.selectedItem).map(([key, value]) => {
        const selectedTab = this.tabs.find(tab => tab.label === key)
        return selectedTab ? selectedTab.items.find(i => i.id === value) : null
      }).filter(item => item)
    },
    viewDetails (item) {
      this.selectedProduct = item
      this.drawerVisible = true
    },
    showSelectedItems () {
      this.selectedItemsModalVisible = true
    },
    // 添加单个商品,直接添加购物车默认选择第一款式
    async addToCart (item) {
      console.log(item)
      console.log(item.skuData[0].id)
      const res = await PostAddCart(item.skuData[0].id, 1)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('Failed to add cart')
      } else {
        this.$message.success('The cart was added successfully')
      }
      this.drawerVisible = false
    },
    // 添加选择商品
    async addselectedToCart () {
      console.log(this.selectedItems)
      const selectedItemsid = this.selectedItems.map(item => ({ dynamicGoodsSkuId: item.skuData[0].id, goodsNumber: 1 }))
      console.log(selectedItemsid)
      const res = await PostAllAddCart(selectedItemsid)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('Failed to add cart')
      } else {
        this.selectedItemsModalVisible = false
        this.$message.success('The cart was added successfully')
      }
    },
    changePage (pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber
      }
    },
    // 记录商品点击次数
    async ProductClicks () {
      await ProductClicks(this.selectedProduct.id)
      // console.log(res)
      this.$router.push(`/Productdetails/${this.selectedProduct.id}`)
    }
  }
}
</script>

<style scoped>
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.pagination-controls span {
  align-self: center;
  font-size: 16px;
  margin: 0 10px;
}
.container {
  margin-top: 20px;
  height: 58vh;
  /* background-color: aqua; */
}

.modeling-image {
  position: relative;
  width: 910px;
  height: 500px;
}

.model-img {
  width: 100%;
  height: 100%;
}

.replace-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tab-container {
  display: flex;
  flex-direction: column;
  /* margin-left: 500px; */
}

.tab-header {
  display: flex;
  margin-bottom: 10px;
}

.tab-title {
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #333; /* Tab background color */
  color: #fff; /* Tab text color */
  border-radius: 4px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.tab-title.active {
  background-color: black; /* Active tab background color */
}

.detailesbtn {
  width: 120px;
  margin-left: 10px;
  height: 40px;
  border-radius: 10px;
  background-color: black;
  color: white;
  text-align: center;
  border: none;
}

.detailesbtn1 {
  width: 120px;
  margin-left: 30px;
  height: 40px;
  border-radius: 10px;
  background-color: black;
  color: white;
  text-align: center;
  border: none;
}

.item-list {
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 10px;
  gap: 20px; /* Increased gap between items */
  /* background-color: aquamarine; */
}
.item-list span{
  /* background-color: aquamarine; */
  /* width: 20px; */
  display: block;
  white-space: nowrap; /* 禁止换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 用省略号替代超出部分 */
}

.item {
  width: 185px;
  height: 210px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  position: relative;
  background-color: white; /* Item background color */
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added transition for animation */
}

.item img {
  width: 100%;
  height: 130px;
  border-radius: 4px 4px 0 0; /* Round the top corners */
}

.item.selected {
  transform: scale(1.05); /* Scale up the selected item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

.details-button {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 添加过渡效果 */
}

.details-button:hover,
.details-button:active {
  background-color: black; /* Button hover and active background color */
}

.details-button:focus {
  outline: none; /* 去掉焦点轮廓 */
}

.selected-items-button {
  margin-top: 10px;
}

.view-selected-button {
  margin-top: 10px;
}

.selected-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.grid-item {
  text-align: center;
}

.grid-item img {
  width: 100%;
  height: 110px;
}

.drawer-product-image {
  margin-left: 10px;
  width: 420px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.drawer-product-name {
  margin-left: 10px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.drawer-product-stock {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.drawer-product-stock h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.drawer-product-stock ul {
  list-style-type: none; /* 去掉默认的列表样式 */
  padding: 0; /* 去掉默认的内边距 */
}

.drawer-product-stock li {
  margin: 5px 0; /* 每个库存项之间的间距 */
  font-size: 14px; /* 字体大小 */
  color: #000000; /* 字体颜色 */
}

.drawer-product-description {
  margin-left: 10px;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.drawer-buttons {
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
  gap: 10px;
}

.bottom-info {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 10px 20px;
  width: 150px;
  height: 60px;
  font-size: 18px;
  margin: 0 5px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.pagination-controls span {
  align-self: center;
  font-size: 16px;
  margin: 0 10px;
}
  .container {
  margin-top: 20px;
  height: 13vh;
  /* background-color: aqua; */
}
  .detailesbtn1 {
    width: 150px;
    font-size: 16px;
    margin-left: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: black;
    color: white;
    text-align: center;
    border: none;
  }

  .item-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 16px;
  }

  .detailesbtn {
    margin-left: 30px;
    width: 200px; /* Adjusted width for mobile */
    height: 40px; /* Adjusted height for mobile */
    font-size: 14px; /* Adjusted font size for mobile */
  }

  .drawer-buttons button {
    width: 100px; /* Adjusted width for mobile */
    height: 30px; /* Adjusted height for mobile */
    font-size: 14px; /* Adjusted font size for mobile */
  }

  .item {
    width: 220px; /* Adjust the item width for smaller screens */
    height: auto; /* Let height adjust automatically */
  }

  .tab-title {
    font-size: 14px; /* Adjust tab font size for mobile */
    padding: 5px 10px; /* Adjust padding for better fit */
  }

  .bottom-info {
    font-size: 14px; /* Adjust font size for mobile */
    margin-top: 10px; /* Adjust margin for mobile */
  }
}
</style>
