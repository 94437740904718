<template>
  <div class="whatsapp-button" @click="openWhatsApp">
    <img src="@/img/icon/whatsApp.png" alt="WhatsApp" />
    <span>Chat with us</span>
  </div>
</template>

<script>
export default {
  methods: {
    openWhatsApp () {
      window.location.href = 'https://api.whatsapp.com/send/?phone=17164274450&text=Hey+there%2C+I+have+a+question%21&type=phone_number&app_absent=0'
    }
  }
}
</script>

<style scoped>
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: rgb(89, 206, 114);
  border-radius: 30px;
  display: flex;
  align-items: center;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
  cursor: pointer;
  color: white;
}

.whatsapp-button img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.whatsapp-button span {
  font-size: 16px;
  color: white;
}
/* 添加媒体查询以处理移动设备样式 */
@media (max-width: 768px) {
  .whatsapp-button {
  position: fixed;
  bottom: 2200px;
  right: 20px;
  padding: 10px 20px;
  background-color: rgb(89, 206, 114);
  border-radius: 30px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  color: white;
}

.whatsapp-button img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.whatsapp-button span {
  font-size: 16px;
  color: white;
}
}
</style>
