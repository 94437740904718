import { render, staticRenderFns } from "./Blogdetail.vue?vue&type=template&id=3b8c8d81&scoped=true"
import script from "./Blogdetail.vue?vue&type=script&lang=js"
export * from "./Blogdetail.vue?vue&type=script&lang=js"
import style0 from "./Blogdetail.vue?vue&type=style&index=0&id=3b8c8d81&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8c8d81",
  null
  
)

export default component.exports