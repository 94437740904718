<template>
  <div class="blog">
    <!-- Header with Page Name and Back Button -->
    <div class="blog-header">
      <el-page-header @back="goBack" title="Return"></el-page-header>
    </div>
    <h2 class="page-title">Blog posts</h2>

    <!-- Blog posts grid -->
    <el-row :gutter="20">
      <el-col :span="8" v-for="post in paginatedPosts" :key="post.id">
        <div @click="handleCardClick(post.id)">
          <el-card shadow="hover" class="blog-card">
            <img :src="post.imageUrl" alt="" class="blog-image" />
            <h3 class="blog-title">{{ post.title }}</h3>
            <p class="blog-date">{{ post.updateTime }}</p>
            <p class="blog-description">{{ getFirstLine(post.content) }}</p>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <!-- Pagination -->
    <el-pagination
      v-if="totalPosts > postsPerPage"
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-size="postsPerPage"
      :total="totalPosts"
      layout="prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
import { getbloglist } from '@/api/blog/blog'
export default {
  name: 'BlogHomeIndex',
  data () {
    return {
      currentPage: 1,
      postsPerPage: 6,
      posts: []
    }
  },
  computed: {
    totalPosts () {
      return this.posts.length
    },
    paginatedPosts () {
      const start = (this.currentPage - 1) * this.postsPerPage
      const end = start + this.postsPerPage
      return this.posts.slice(start, end)
    }
  },
  created () {
    this.getbloglist()
  },
  methods: {
    async getbloglist () {
      const res = await getbloglist()
      console.log(res)
      this.posts = res.data
    },
    handleCardClick (id) {
      this.$router.push(`/blogdetail/${id}`)
    },
    handlePageChange (page) {
      this.currentPage = page
    },
    goBack () {
      this.$router.go(-1)
    },
    getFirstLine (content) {
      // 使用正则表达式提取第一行文本
      const text = content.replace(/<[^>]*>/g, '') // 移除 HTML 标签
      const firstLine = text.split('\n')[0] // 获取第一行
      return firstLine || '' // 如果没有内容，返回空字符串
    }
  }
}
</script>

<style scoped>
.blog-header {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
}

.blog-card {
  margin-bottom: 20px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.blog-date {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.blog-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
  overflow: hidden;             /* Hide overflow text */
  text-overflow: ellipsis;     /* Show ellipsis when the text overflows */
  white-space: nowrap;          /* Prevent the text from wrapping to the next line */
}
</style>
