<template>
  <div class="user-orders">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h2>My Orders</h2>

    <!-- Display all orders -->
    <div class="orders-grid" v-if="orders.length > 0">
      <div v-for="order in orders" :key="order.id" class="order-card">
        <div class="order-status">
          <p>{{ order.createTime }}</p>
        </div>

        <div class="order-image">
          <img :src="order.orderDetailList[0].dynamicGoodsSku.image" alt="Order Image" />
        </div>

        <div class="order-info">
          <p>Order #{{ order.id }}</p>
          <!-- View Order Details button -->
          <button @click="viewOrderDetails(order.id)">View Order Details</button>
        </div>
      </div>
    </div>

    <el-empty description="Your order is empty" v-else></el-empty>

    <!-- Refund Reason Dialog -->
    <el-dialog title="Refund Reason" :visible.sync="refundDialogVisible">
      <el-input
        type="textarea"
        v-model="refundReason"
        placeholder="Please enter the reason for the refund/return"
        rows="4"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitRefundRequest">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getmyOrder } from '@/api/cart/cart' // 导入获取所有订单的 API
export default {
  name: 'UserOrders',
  data () {
    return {
      orders: [], // 用于存储所有订单数据
      refundDialogVisible: false,
      refundReason: '',
      selectedOrderId: null
    }
  },
  created () {
    this.getmyOrder() // 获取所有订单
  },
  methods: {
    // 获取所有已下单的订单
    async getmyOrder () {
      try {
        const res = await getmyOrder(this.userid) // 获取订单数据
        this.orders = res.data // 假设返回的数据中包含 orders
        console.log('全部订单', res)
      } catch (error) {
        console.error('Failed to fetch orders:', error)
      }
    },
    viewOrderDetails (orderId) {
      this.$router.push('/OrderDetails/' + orderId) // 跳转到订单详情页
    },
    showRefundDialog (orderId) {
      this.selectedOrderId = orderId
      this.refundDialogVisible = true
    },
    async submitRefundRequest () {
      if (!this.refundReason) {
        return this.$message.error('Please enter a reason for the refund/return.')
      } else {
        this.$message.success('The application for return and refund is successful, please wait patiently for the administrator to review it')
        this.refundDialogVisible = false
      }
    }
  }
}
</script>

<style scoped>
.user-orders {
  padding: 20px;
}

.orders-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.order-image {
  width: 300px;
  height: 200px;
}

.order-image img {
  width: 100%;
  height: 100%;
}

.order-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  background-color: #fff;
}

.order-status {
  margin-bottom: 10px;
}

.order-info {
  margin-top: 10px;
}
.order-info p{
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}
button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: black;
  margin-right: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #000000;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Media Queries */
@media (max-width: 768px) {
  .user-orders h2 {
    font-size: 20px;
  }

  .orders-grid {
    flex-direction: column;
    font-size: 16px;
  }

  .order-card {
    width: 100%;
  }

  .order-image {
    width: 400px;
    height: 200px;
  }

  button {
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
