import request from '@/utils/request'

// 创建订单
export const PostOrder = (dynamicGoodsList, payWay, userAddressId, expectContactStartTime, expectContactEndTime) => {
  return request.post('/business/order/saveRecord', {
    dynamicGoodsList, payWay, userAddressId, expectContactStartTime, expectContactEndTime
  })
}

// 获取分页订单
export const getTabOrder = (deliverStatus) => {
  return request.post('/business/order/listPageRecord', {
    deliverStatus,
    payStatus: 3
  })
}

// 获取分页订单
export const getTabPayOrder = () => {
  return request.post('/business/order/listPageRecord', {
    payStatus: 1
  })
}
