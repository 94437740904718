<template>
  <div class="aboutus">
    <HeaderInformation></HeaderInformation>
    <div class="abnoutustitle">
      <h1>High-end Furniture Customization</h1>
    </div>
    <div class="titlecontent">
      <li>Craft Your Luxury, Define Your Home. Discover Exquisite Elegance with Our Bespoke Furniture</li>
      <li>Creations. Tailored to Perfection, Each Piece a Testament to Timeless Beauty and Uncompromising</li>
      <li>Quality. Elevate Your Living Space with Our Artisanal Excellence.</li>
    </div>
    <!-- 列表 -->
    <div v-for="item in aboutUsList" :key="item.id" class="aboutuscontent">
      <div v-if="item.title === 'Professional manufacturing process'" class="contentright">
        <div class="contentitle">{{ item.title }}</div>
        <div class="contenttext">{{ item.content }}</div>
      </div>
      <div v-if="item.title !== 'Professional manufacturing process'" class="contentleft">
        <img :src="item.imageUrl" alt="">
      </div>
      <div v-if="item.title !== 'Professional manufacturing process'" class="contentright">
        <div class="contentitle">{{ item.title }}</div>
        <div class="contenttext">{{ item.content }}</div>
      </div>
      <div v-if="item.title === 'Professional manufacturing process'" class="contentleft">
        <img :src="item.imageUrl" alt="">
      </div>
    </div>
    <BottomInformation></BottomInformation>
  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import BottomInformation from '@/components/BottomInformation.vue'
import { getaboutuslist } from '@/api/Contactus/contactus'

export default {
  name: 'AboutusIndex',
  components: {
    HeaderInformation,
    BottomInformation
  },
  data () {
    return {
      aboutUsList: []
    }
  },
  mounted () {
    this.getaboutuslist()
  },
  methods: {
    async getaboutuslist () {
      const res = await getaboutuslist()
      this.aboutUsList = res.data
    }
  }
}
</script>

<style scoped>
.abnoutustitle {
  text-align: center;
  line-height: 70px;
  margin: auto;
  width: 100%; /* 使用百分比以确保响应性 */
  max-width: 830px; /* 最大宽度 */
}

.abnoutustitle h1 {
  font-size: 36px; /* 默认字体大小 */
  margin: 0; /* 去除默认外边距 */
  color: black; /* 设定字体颜色 */
}

/* 其他部分的样式保持不变 */
.titlecontent {
  width: 100%;
  max-width: 820px; /* 最大宽度 */
  height: auto; /* 自适应高度 */
  margin: auto;
  padding: 5px;
}

.titlecontent li {
  list-style: none;
  font-size: 16px;
  margin-bottom: 15px;
  color: rgba(147, 153, 177);
}

.aboutuscontent {
  width: 100%;
  max-width: 1160px; /* 最大宽度 */
  height: auto; /* 自适应高度 */
  margin: 50px auto;
  display: flex; /* 使用 flexbox 进行布局 */
  flex-wrap: wrap; /* 允许换行 */
}

.contentleft,
.contentright {
  flex: 1; /* 相同的占比 */
  max-width: 580px; /* 最大宽度 */
  height: auto; /* 自适应高度 */
}

.contentleft img {
  width: 100%;
  height: auto; /* 自适应高度 */
  border-radius: 15px 0 0 15px; /* 添加圆角 */
}

.contentitle {
  margin-top: 60px;
  margin-left: 50px;
  width: 100%; /* 使用百分比以确保响应性 */
  max-width: 400px; /* 最大宽度 */
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.contenttext {
  width: 100%;
  max-width: 480px; /* 最大宽度 */
  margin-left: 50px;
  color: rgba(147, 153, 177);
  line-height: 25px;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .abnoutustitle h1 {
    font-size: 30px; /* 在中等屏幕上调整标题大小 */
  }

  .contentitle {
    font-size: 24px; /* 调整字体大小 */
  }

  .contenttext {
    font-size: 14px; /* 调整字体大小 */
  }

  .titlecontent li {
    font-size: 14px; /* 调整字体大小 */
  }
}

@media (max-width: 480px) {
  .abnoutustitle h1 {
    font-size: 24px; /* 在小屏幕上进一步调整标题大小 */
  }

  .contentitle {
    font-size: 20px; /* 进一步调整字体大小 */
  }

  .contenttext {
    font-size: 12px; /* 进一步调整字体大小 */
  }

  .titlecontent li {
    font-size: 12px; /* 进一步调整字体大小 */
  }
}
</style>
