import { render, staticRenderFns } from "./addcart.vue?vue&type=template&id=6285535b&scoped=true"
import script from "./addcart.vue?vue&type=script&lang=js"
export * from "./addcart.vue?vue&type=script&lang=js"
import style0 from "./addcart.vue?vue&type=style&index=0&id=6285535b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6285535b",
  null
  
)

export default component.exports