<template>
  <div class="addfavorites">
    <HeaderInformation></HeaderInformation>
    <div class="favoritestitle">Collection</div>
    <div class="cartcontent">
      <div class="cartcontentleft">
        <div class="lefttop">
          <el-checkbox v-model="isall" class="custom-checkbox">Select all</el-checkbox>
          <!-- <el-button size="mini" class="custom-button">Move into favorites</el-button>
          <el-button size="mini" class="custom-button">Delete</el-button> -->
          <button class="custom-button" @click="MoveCollectionList">Move into the custom list</button>
          <button class="custom-button" @click="delCollectionlist">Delete</button>
        </div>
        <!-- 收藏列表 -->
        <div class="favoriteslist" v-for="item in favoriteslist" :key="item.id">
          <div class="favoriteslistleft">
            <el-checkbox v-model="item.ischecked" class="custom-checkbox"></el-checkbox>
          </div>
          <div class="favoriteslistright">
            <div class="favoritesimg">
              <img :src="item.dynamicGoodsSku.skuProperties.image" alt="">
            </div>
            <div class="favoritesname">
              {{item.dynamicGoodsSku.skuProperties.productsName}}
            </div>
            <div class="favoritessku">
              <ul>
                <li v-for="(value, key) in getDynamicAttributes(item)" :key="key">
                  {{ formatKey(key) }}: <span>{{ value }}</span>
                </li>
              </ul>
            </div>
            <div class="favoritesprice">
              <!-- ${{ (Number(item.dynamicGoodsSku.skuProperties.productsNewPrice  ) * item.goodsNumber).toFixed(2) }} -->
            </div>
            <div class="favoritesnum">
              <el-input-number size="mini" v-model="item.goodsNumber" :min="1" :max="10000000" @change="updateTotal(item)"></el-input-number>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import { SelCollection, ChangeCollectionNum, DelCollectionList, MoveCollectionList } from '@/api/cart/cart'
export default {
  name: 'FavoritesIndex',
  components: {
    HeaderInformation
  },
  data () {
    return {
      favoriteslist: []
    }
  },
  computed: {
    isall: {
      get () {
        return this.favoriteslist.every(item => item.ischecked === true)
      },
      set (value) {
        this.favoriteslist.forEach((item) => { item.ischecked = value })
      }
    },
    merchandiseTotal () {
      return this.favoriteslist
        .filter(item => item.ischecked)
        .reduce((total, item) => total + Number(item.dynamicGoodsSku.skuProperties.productsNewPrice) * item.goodsNumber, 0)
        .toFixed(2)
    },
    total () {
      const fullServiceDelivery = 119.00
      const estimatedTaxes = 139.93
      return (parseFloat(this.merchandiseTotal) + fullServiceDelivery + estimatedTaxes).toFixed(2)
    }
  },
  created () {
    this.SelCollection()
  },
  methods: {
    async updateTotal (item) {
      // This method is called whenever the item number changes
      // console.log(item.goodsNumber)
      // console.log(item.id)
      await ChangeCollectionNum(item.goodsNumber, item.id)
      this.SelCollection()
    },
    async SelCollection () {
      const res = await SelCollection()
      // console.log(res)
      this.favoriteslist = res.data.map(item => ({
        ...item, // 保留原始字段
        ischecked: res.data.includes(item.id) // 根据选中状态初始化 ischeck
      }))
    },
    formatKey (key) {
      return key.charAt(0).toUpperCase() + key.slice(1)
    },
    // 删除收藏
    async delCollectionlist () {
      console.log(this.favoriteslist)
      const filterCollectionlist = this.favoriteslist.filter(item => (item.ischecked)).map(item => item.id)
      console.log(filterCollectionlist)
      const res = await DelCollectionList(filterCollectionlist)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('Failed to delete collection')
      } else {
        this.$message.success('The collection was deleted')
        this.SelCollection()
      }
    },
    // 收藏商品移入购物车
    async MoveCollectionList () {
      const moveCollectionList = this.favoriteslist.filter(item => item.ischecked).map(item => item.id)
      console.log(moveCollectionList)
      const res = await MoveCollectionList(moveCollectionList)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('The item failed to be moved into the cart, please try again')
      } else {
        this.$message.success('The item was successfully moved into the shopping cart')
        this.SelCollection()
      }
    },
    // 获取规格
    getDynamicAttributes (item) {
      console.log(item)
      const { USAstock, baseGoodsId, id, image, mainImageUrl, productsNewPrice, productsOldPrice, productsName, ...rest } = item.dynamicGoodsSku.skuProperties
      return rest
    }

  }
}
</script>

<style scoped>
.favoritestitle{
  width: 1510px;
  height: 80px;
  border-bottom: 1px solid #979899;
  line-height: 80px;
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  margin:  auto ;
}
.cartcontent{
  width: 1510px;
  height: 800px;
  margin: 10px auto;
  /* background-color:tomato; */
}
.favoritescontentleft{
  float: left;
  width: 1030px;
  border-radius: 10px;
}
.favoritescontentright{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  float: right;
  margin-right: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 430px;
  height: 400px;
}

.order-summary {
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.total {
  font-weight: bold;
  border-top: 1px solid #979899;
  margin-top: 10px;
  padding-top: 10px;
}
.favoritesbtn{
  margin-top: 20px;
  text-align: right;
  width: 440px;
  height: 50px;
}
.lefttop{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  width: 99.2%;
  height: 60px;
  background-color: rgba(255, 255, 255);
  line-height: 60px;
  padding-left: 10px;
}
.favoriteslist{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 150px;
  margin-top: 20px;
  border-radius: 10px;
}
.favoriteslistleft{
  border-radius: 10px 0 0 10px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 150px;
}
.favoriteslistright{
  float: left;
  width: 990px;
  border-radius: 0 10px 10px 0;
  height: 150px;
}
.favoritesimg{
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}
.favoritesimg img{
  width: 100%;
  height: 100%;
}
.favoritesname{
  padding-left: 10px;
  float: left;
  width: 290px;
  padding-top: 10px;
  height: 140px;
    /* background-color: aquamarine; */
    -webkit-box-orient: vertical; /* 垂直排列子元素 */
    -webkit-line-clamp: 3; /* 显示的行数 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    word-wrap: break-word; /* 允许内容换行 */
    white-space: normal; /* 正常的换行规则 */
}
.favoritessku{
  position: relative;
  /* padding-left: 10px; */
  float: left;
  width: 145px;
  /* padding-top: 10px; */
  height: 140px;
  /* background-color: aqua; */
}
.favoritessku  li{
  /* position: absolute; */
  left: 0;
  color: #979899;
  list-style: none;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 140px;
  /* background-color: brown; */
}
.favoritesprice{
  font-size: 22px;
  color: black;
  float: left;
  width: 174px;
  padding-top: 10px;
  height: 140px;
}
.favoritesnum{
  margin-left: 10px;
  padding-top: 10px;
  height: 140px;
  width: 130px;
  float: left;
}

/* Custom styles for checkboxes */
::v-deep .custom-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
::v-deep .custom-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: black;
  background-color: black;
}
::v-deep .custom-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: black;
}
::v-deep .custom-checkbox .el-checkbox__label {
  color: black;
}

/* Custom styles for buttons */
.custom-button {
  margin-left: 10px;
  background-color: transparent; /* Normal background color */
  color: black; /* Normal text color */
  border: 1px solid #dcdcdc; /* Normal border color */
  padding: 5px 10px; /* Add some padding for better appearance */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hover and active states */
.custom-button:hover {
  background-color: black; /* Background color on hover */
  color: white; /* Text color on hover */
}

.custom-button:active {
  background-color: #333; /* Darker background color on click */
  color: white; /* Text color on click */
}
/* Responsive styles for different screen sizes */
@media (max-width: 768px) {
  .favoritestitle{
  width: 1510px;
  height: 80px;
  border-bottom: 1px solid #979899;
  line-height: 80px;
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  margin:  auto ;
}
.cartcontent{
  width: 1510px;
  height: 800px;
  margin: 10px auto;
  /* background-color:tomato; */
}
.favoritescontentleft{
  float: left;
  width: 1030px;
  border-radius: 10px;
}
.favoritescontentright{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  float: right;
  margin-right: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 430px;
  height: 400px;
}

.order-summary {
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.total {
  font-weight: bold;
  border-top: 1px solid #979899;
  margin-top: 10px;
  padding-top: 10px;
}
.favoritesbtn{
  margin-top: 20px;
  text-align: right;
  width: 440px;
  height: 50px;
}
.lefttop{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  width: 99.2%;
  height: 60px;
  background-color: rgba(255, 255, 255);
  line-height: 60px;
  padding-left: 10px;
}
.favoriteslist{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 150px;
  margin-top: 20px;
  border-radius: 10px;
}
.favoriteslistleft{
  border-radius: 10px 0 0 10px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 150px;
}
.favoriteslistright{
  float: left;
  width: 990px;
  border-radius: 0 10px 10px 0;
  height: 150px;
}
.favoritesimg{
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}
.favoritesimg img{
  width: 100%;
  height: 100%;
}
.favoritesname{
  padding-left: 10px;
  float: left;
  width: 290px;
  padding-top: 10px;
  height: 140px;
}
.favoritessku{
  position: relative;
}
.favoritessku  li{
  font-size: 16px;
  line-height: 40px;
  margin-top: -40px; /* 根据需要调整 */
  margin-left: -100px; /* 根据需要调整 */
  color: #979899;
  list-style: none;
}
.favoritesprice{
  font-size: 22px;
  color: black;
  float: left;
  width: 174px;
  padding-top: 10px;
  height: 140px;
}
.favoritesnum{
  margin-left: 10px;
  padding-top: 10px;
  height: 140px;
  width: 130px;
  float: left;
}

/* Custom styles for checkboxes */
::v-deep .custom-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
::v-deep .custom-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: black;
  background-color: black;
}
::v-deep .custom-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: black;
}
::v-deep .custom-checkbox .el-checkbox__label {
  color: black;
}

/* Custom styles for buttons */
.custom-button {
  font-size: 16px;
  margin-left: 10px;
  background-color: transparent; /* Normal background color */
  color: black; /* Normal text color */
  border: 1px solid #dcdcdc; /* Normal border color */
  padding: 5px 10px; /* Add some padding for better appearance */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hover and active states */
.custom-button:hover {
  background-color: black; /* Background color on hover */
  color: white; /* Text color on hover */
}

.custom-button:active {
  background-color: #333; /* Darker background color on click */
  color: white; /* Text color on click */
}
  .favoritestitle {
    font-size: 20px; /* Slightly smaller font size for tablets */
  }

  .favoritesname {
    font-size: 16px; /* Smaller font size for product names */
  }

  .favoritesprice {
    font-size: 20px; /* Smaller font size for price */
  }
  .custom-checkbox {
    font-size: 12px; /* Further adjust font size for mobile */
  }
}

</style>
