<template>
  <div class="addcart">
    <HeaderInformation></HeaderInformation>
    <div class="carttitle">Customize your list</div>
    <div class="cartcontent" v-if="cartlist.length > 0">
      <div class="cartcontentleft">
        <div class="lefttop">
          <el-checkbox v-model="isall" class="custom-checkbox">Select all</el-checkbox>
          <button class="custom-button" @click="Movecarlist">Move into Collection</button>
          <button class="custom-button" @click="delcarlist">Delete</button>
        </div>
        <!-- 定制列表 -->
        <div class="cartlist" v-for="item in cartlist" :key="item.id">
          <div class="cartlistleft">
            <el-checkbox v-model="item.ischecked" class="custom-checkbox"></el-checkbox>
          </div>
          <div class="cartlistright">
            <div class="cartimg">
              <img :src="item.dynamicGoodsSku.skuProperties.image" alt="">
            </div>
            <div class="cartname">
              {{ item.dynamicGoodsSku.skuProperties.productsName }}
            </div>
            <div class="cartsku">
              <ul>
                <li v-for="(value, key) in getDynamicAttributes(item)" :key="key">
                  {{ formatKey(key) }}: <span>{{ value }}</span>
                </li>
              </ul>
            </div>
            <div class="cartprice">
              <!-- ${{ (Number(item.dynamicGoodsSku.skuProperties.productsNewPrice ) * item.goodsNumber).toFixed(2) }} -->
            </div>
            <div class="cartnum">
              <el-input-number size="mini" v-model="item.goodsNumber" :min="1" :max="10000000" @change="updateTotal(item)"></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <!-- 价格统计 -->
      <div class="cartcontentright">
        <div class="righttitle">Customize order summaries</div>
        <div class="order-summary">
          <div class="summary-item">
            <span class="item-name">You choose the style and product you want to customize</span>
            <!-- <span class="item-price">${{ merchandiseTotal }}</span> -->
          </div>
          <div class="summary-item">
            <span class="item-name">Then enter your address and the time you wish to be contacted</span>
            <!-- <span class="item-price">$139.93</span> -->
          </div>
          <div class="summary-item">
            <span class="item-name">We will contact you specifically according to the customized order you need</span>
            <!-- <span class="item-price">$119.00</span> -->
          </div>
          <div class="summary-item total">
            <!-- <span class="item-name">Total</span>
            <span class="item-price">${{ total }}</span> -->
          </div>
        </div>
        <div class="cartbtn">
          <el-button type="danger" @click="getOrder" >Checkout</el-button>
        </div>
      </div>
    </div>
    <el-empty v-else description="Your cart is empty"></el-empty>
  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import { getuserinfo } from '@/api/login/register'
import { Seladdress } from '@/api/address/address'
import { SelAddCartList, DelCartList, ChangeCartNum, MoveCartList } from '@/api/cart/cart'

export default {
  name: 'AddcartIndex',
  components: {
    HeaderInformation
  },
  data () {
    return {
      cartlist: [],
      telephone: '',
      addresslist: [],
      getproductlistnum: []
    }
  },
  computed: {
    isall: {
      get () {
        return this.cartlist.every(item => item.ischecked === true)
      },
      set (value) {
        this.cartlist.forEach((item) => { item.ischecked = value })
      }
    },
    merchandiseTotal () {
      return this.cartlist
        .filter(item => item.ischecked)
        .reduce((total, item) => total + Number(item.dynamicGoodsSku.skuProperties.productsNewPrice) * item.goodsNumber, 0)
        .toFixed(2)
    },
    total () {
      const fullServiceDelivery = 119.00
      const estimatedTaxes = 139.93
      return (parseFloat(this.merchandiseTotal) + fullServiceDelivery + estimatedTaxes).toFixed(2)
    }
  },
  created () {
    this.SelAddCartList()
    this.getuserinfo()
    this.Seladdress()
  },
  methods: {
    // 更新数量
    async updateTotal (item) {
      await ChangeCartNum(item.goodsNumber, item.id)
      this.SelAddCartList()
    },
    // 查询购物车列表
    async SelAddCartList () {
      const res = await SelAddCartList()
      this.cartlist = res.data.map(item => ({
        ...item,
        ischecked: false // Initialize ischecked
      }))
      console.log(this.cartlist)
    },
    formatKey (key) {
      return key.charAt(0).toUpperCase() + key.slice(1)
    },
    // 删除购物车
    async delcarlist () {
      const filtercarlist = this.cartlist.filter(item => item.ischecked).map(item => item.id)
      const res = await DelCartList(filtercarlist)
      if (res.code !== 200) {
        return this.$message.error('Failed to delete the cart')
      } else {
        this.$message.success('The cart was deleted')
        this.SelAddCartList()
      }
    },
    // 移动到收藏
    async Movecarlist () {
      const movecarlist = this.cartlist.filter(item => item.ischecked).map(item => item.id)
      const res = await MoveCartList(movecarlist)
      if (res.code !== 200) {
        return this.$message.error('The item failed to be moved to favorites, please try again')
      } else {
        this.$message.success('The item was successfully moved to favorites')
        this.SelAddCartList()
      }
    },
    // 获取个人信息
    async getuserinfo () {
      const res = await getuserinfo()
      this.telephone = res.data.telephone
    },
    // 选择地址
    async Seladdress () {
      const res = await Seladdress()
      this.addresslist = res.data
    },
    // 创建订单
    async getOrder () {
      const getproductlist = this.cartlist.filter(item => item.ischecked).map(item => ({
        dynamicGoodsSkuId: item.dynamicGoodsSku.skuProperties.id,
        goodsNumber: item.goodsNumber
      }))
      // console.log(getproductlist)
      this.getproductlistnum = getproductlist
      if (this.getproductlistnum.length > 0) {
        const Data = encodeURIComponent(JSON.stringify(getproductlist))
        // const idsString = getproductlist.join(',') // 将 ID 拼接成字符串
        this.$router.push('/order/' + Data)
      } else {
        return this.$message.error('Please select the product you want to customize')
      }
    },
    // 获取规格
    getDynamicAttributes (item) {
      const { USAstock, baseGoodsId, id, image, mainImageUrl, productsNewPrice, productsOldPrice, productsName, ...rest } = item.dynamicGoodsSku.skuProperties
      console.log(rest)
      return rest
    }
  }
}
</script>

<style scoped>
.carttitle{
  width: 1510px;
  height: 80px;
  border-bottom: 1px solid #979899;
  line-height: 80px;
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  margin:  auto ;
}
.cartcontent{
  width: 1510px;
  height: 800px;
  margin: 10px auto;
}
.cartcontentleft{
  float: left;
  width: 1030px;
  border-radius: 10px;
}
.cartcontentright{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  float: right;
  margin-right: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 430px;
  height: 400px;
}

.order-summary {
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.total {
  font-weight: bold;
  border-top: 1px solid #979899;
  margin-top: 10px;
  padding-top: 10px;
}
.cartbtn{
  margin-top: 20px;
  text-align: right;
  width: 440px;
  height: 50px;
}
.lefttop{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 99;
  top: 0;
  width: 99.2%;
  height: 60px;
  background-color: rgba(255, 255, 255);
  line-height: 60px;
  padding-left: 10px;
}
.cartlist{
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 150px;
  margin-top: 20px;
  border-radius: 10px;
  /* background-color: aqua; */
}
.cartlistleft{
  border-radius: 10px 0 0 10px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 150px;
}
.cartlistright{
  float: left;
  width: 990px;
  border-radius: 0 10px 10px 0;
  height: 150px;
}
.righttitle{
  font-size: 28px;
  font-weight: bold;
}
.cartimg{
  margin-top: 10px;
  float: left;
  margin-left: 5px;
  width: 150px;
  height: 100px;
}
.cartimg img{
  width: 100%;
  height: 100%;
}
.cartname{
  padding-left: 10px;
  float: left;
  width: 290px;
  padding-top: 10px;
  height: 140px;
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}
.cartsku{
  position: absolute;
  left: 330px;
  /* top: -10px; */
  /* padding-left: 10px; */
  float: left;
  width: 145px;
  /* padding-top: 10px; */
  height: 140px;
  /* background-color: aqua; */
}
.cartsku  li{
  color: #979899;
  list-style: none;
  margin-right: 20px;
  width: 140px;
  /* background-color: brown; */
}
.cartprice{
  font-size: 22px;
  color: black;
  float: left;
  width: 174px;
  padding-top: 10px;
  height: 140px;
}
.cartnum{
  margin-left: 10px;
  padding-top: 10px;
  height: 140px;
  width: 130px;
  float: left;
}

/* Custom styles for checkboxes */
::v-deep .custom-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
::v-deep .custom-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: black;
  background-color: black;
}
::v-deep .custom-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: black;
}
::v-deep .custom-checkbox .el-checkbox__label {
  color: black;
}

/* Custom styles for buttons */
.custom-button {
  margin-left: 10px;
  background-color: transparent; /* Normal background color */
  color: black; /* Normal text color */
  border: 1px solid #dcdcdc; /* Normal border color */
  padding: 5px 10px; /* Add some padding for better appearance */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

/* Hover and active states */
.custom-button:hover {
  background-color: black; /* Background color on hover */
  color: white; /* Text color on hover */
}

.custom-button:active {
  background-color: #333; /* Darker background color on click */
  color: white; /* Text color on click */
}
/* Responsive styles for different screen sizes */
@media (max-width: 768px) {
  .custom-button {
    font-size: 16px;
  margin-left: 10px;
  background-color: transparent; /* Normal background color */
  color: black; /* Normal text color */
  border: 1px solid #dcdcdc; /* Normal border color */
  padding: 5px 10px; /* Add some padding for better appearance */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}
.cartlist{
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 150px;
  margin-top: 20px;
  border-radius: 10px;
  /* background-color: aqua; */
}
.cartsku {
  position: absolute;
  left: 100px;
  top: -70px;
}
.cartsku  li{
  font-size: 16px;
  color: #979899;
  list-style: none;
}
.cartbtn{
  margin-top: 20px;
  text-align: right;
  width: 340px;
  height: 50px;
}
.cartcontentright{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* position: sticky; */
  /* z-index: 99;
  top: 0; */
  float: right;
  margin-right: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 330px;
  height: 400px;
}
.cartcontentright h1{
  font-size: 10px;
}
  .carttitle {
    font-size: 20px; /* Slightly smaller for mobile */
  }

  .cartname {
    font-size: 16px; /* Smaller font size for product names */
  }

  .cartprice {
    font-size: 20px; /* Smaller font size for price */
  }

  .summary-item {
    font-size: 14px; /* Smaller font size for summary items */
  }

  .total {
    font-size: 16px; /* Smaller font size for total */
  }
}
</style>
