<template>
  <div>
    <button @click="goBack">return</button>
    <h1 class="main-title">Case VR display</h1>
    <h2 class="sub-title">You can click on the display image below to view the corresponding VR effect</h2>
    <div class="showCanvas" id="viewer"></div>
    <div class="vrimglist">
      <button class="scroll-button" @click="scrollLeft">←</button>
      <div class="scroll-container" ref="scrollContainer">
        <div class="item" v-for="(i,index) in items" :key="index" @click="getimgurl(i)">
          <div class="item-content">
            <div class="item-image">
              <img :src="i.remark" alt="项目图片" />
            </div>
            <div class="item-description">{{i.sceneName}}</div>
          </div>
        </div>
      </div>
      <button class="scroll-button" @click="scrollRight">→</button>
    </div>
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import { SelVRlist } from '@/api/VR/VR'
export default {
  name: 'CommodityIndex',
  data () {
    return {
      viewer: null,
      img: require('@/img/VR/3.jpeg'),
      items: []// 1到20的项目编号
    }
  },
  mounted () {
    this.SelVRlist()
    this.viewer = new Viewer({
      container: document.querySelector('#viewer'),
      panorama: this.img,
      size: {
        width: '60vw',
        height: '60vh'
      }
    })
  },
  created () {
    console.log(this.items)
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    scrollLeft () {
      const container = this.$refs.scrollContainer
      if (container) {
        container.scrollBy({ left: -200, behavior: 'smooth' })
      }
    },
    scrollRight () {
      const container = this.$refs.scrollContainer
      if (container) {
        container.scrollBy({ left: 200, behavior: 'smooth' })
      }
    },
    async SelVRlist () {
      const res = await SelVRlist()
      console.log(res)
      this.items = res.data
    },
    getimgurl (i) {
      // console.log(i)
      this.img = i.remark
      this.viewer.setPanorama(this.img) // 更新 Viewer 的全景图像
    }
  }
}
</script>

<style scoped>
.item-description {
  font-size: 14px; /* 描述大小 */
  color: #333; /* 描述颜色 */
  text-align: center; /* 描述居中 */
  word-wrap: break-word; /* 允许长单词换行 */
  overflow-wrap: break-word; /* 兼容性处理 */
  white-space: normal; /* 正常空白处理 */
}

.main-title {
  text-align: center; /* 标题居中 */
  font-size: 24px; /* 主标题大小 */
  margin: 20px 0; /* 上下间距 */
}

.sub-title {
  text-align: center; /* 小标题居中 */
  font-size: 16px; /* 小标题大小 */
  color: #555; /* 小标题颜色 */
  margin-bottom: 20px; /* 下方间距 */
}
.showCanvas {
  margin: 20px auto;
  width: 800px;
  height: 500px;
}
button {
  margin-bottom: 10px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border: none;
  color: white;
  background-color: black;
}
.vrimglist {
  display: flex;
  margin: auto;
  align-items: center;
  width: 60%;
  overflow: hidden;
}
.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  scrollbar-width: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.item {
  flex: 0 0 auto;
  width: 200px;
  margin: 10px;
}
.item-content {
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  align-items: center;
  background-color: rgb(253, 255, 255);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* 柔和阴影 */
  padding: 10px;
  border-radius: 5px;
}
.item-image img {
  width: 100%; /* 图片宽度100% */
  height: auto; /* 自适应高度 */
  border-radius: 5px; /* 圆角 */
}
.item-description {
  font-size: 14px; /* 描述大小 */
  color: #333; /* 描述颜色 */
  text-align: center; /* 描述居中 */
}
.scroll-button {
  background-color: grey;
  color: white;
  border: none;
  cursor: pointer;
}
/* 媒体查询 */
@media (max-width: 768px) {
  button {
  margin-bottom: 10px;
  width: 200px;
  height: 80px;
  line-height: 50px;
  border: none;
  color: white;
  background-color: black;
}
  .showCanvas {
    width: 100%; /* 在小屏幕上宽度为100% */
    height: 300px; /* 调整高度 */
  }

  .item {
    width: 340px; /* 调整项目宽度 */
    margin: 5px; /* 调整间距 */
  }

  .item-description {
    font-size: 12px; /* 调整描述大小 */
  }

  .scroll-button {
    width: 60px; /* 调整按钮宽度 */
    height: 45px; /* 调整按钮高度 */
  }
}

@media (max-width: 480px) {
  button {
  margin-bottom: 10px;
  width: 300px;
  height: 120px;
  line-height: 120px;
  border: none;
  color: white;
  background-color: black;
}
  .item {
    width: 300px; /* 更小屏幕上的项目宽度 */
  }

  .item-description {
    font-size: 10px; /* 更小屏幕上的描述大小 */
  }

  .scroll-button {
    width: 50px; /* 更小屏幕上的按钮宽度 */
    height: 50px; /* 更小屏幕上的按钮高度 */
    line-height: 50px;
  }
}
</style>
