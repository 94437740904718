<template>
  <div class="login">
    <!-- 头部 -->
    <div class="logintitle">
      <div class="loginlogo">
        <img src="@/img/icon/logo.jpg" alt="">
      </div>
    </div>
    <!-- 内容 -->
    <div class="loginbanner">
      <div class="loginform">
        <h1>Welcome back!</h1>
        <h4>Thousands of products awaiting your selection.</h4>
        <div class="input-container">
          <el-input v-model="email" placeholder="Email" class="input-field"></el-input>
        </div>
        <div class="input-container">
          <el-input placeholder="Password" v-model="password" class="input-field" show-password></el-input>
        </div>
        <div class="button-container">
          <el-button type="primary" class="login-button" @click="getlogin()">Login Now</el-button>
        </div>
        <el-divider style="color: #979899;"><a style="color: #979899;">New to FUFOFU?</a></el-divider>
        <div class="button-container">
          <el-button type="primary" class="register-button" @click="$router.push('/register')">Create an Account</el-button>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="CopyrightInformation">
      <p>© 2019-2024 GigaCloud Technology (Hong Kong) Limited. All Rights Reserved</p>
      <p>
        <a @click="$router.push('/Policy')">Privacy Policy</a>
        <span class="separator">|</span>
        <a @click="$router.push('/WebsiteTerms')">Terms and Conditions</a>
      </p>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { getlogin } from '@/api/login/register'
export default {
  name: 'LoginIndex',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    async getlogin () {
      const res = await getlogin(md5(this.password), this.email)
      console.log(res)
      if (res.code !== 200) {
        this.$message.error(res.msg)
      } else {
        this.$store.commit('user/setUserinfo', {
          access_token: res.data.access_token,
          refresh_token: res.data.refresh_token
        })
        this.$message.success(res.msg)
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>
/* 版心 */
.w {
  width: 1800px;
  margin: auto;
}
/* 头部 */
.logintitle {
  height: 51px;
}
.loginlogo {
  width: 206px;
  height: 100%;
  margin-left: 50px;
}
.loginlogo img {
  width: 100%;
  height: 100%;
}
/* 内容区域 */
.loginbanner {
  border-top: 1px solid rgba(204, 204, 204);
  border-bottom: 1px solid rgba(204, 204, 204);
  display: flex;
  justify-content: flex-end; /* 靠右对齐 */
  align-items: center; /* 垂直居中 */
  width: 100%; /* 让它自适应宽度 */
  height: 780px;
  background-image: url('@/img/login/login.png');
  background-size: cover; /* 使背景图片覆盖整个区域 */
  background-position: center; /* 让背景图片居中 */
}

/* 登录表单 */
.loginform {
  margin-right: 200px;
  width: 550px;
  height: 610px;
  background-color: white;
  border-radius: 10px;
  padding: 20px; /* 添加一些内边距 */
}
.loginform h1 {
  margin-top: 70px;
}
.loginform h4 {
  font-weight: normal;
}

/* 输入框和按钮容器 */
.input-container, .button-container {
  width: 100%; /* 使其占满父容器的宽度 */
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 25px; /* 按钮与上方内容的间距 */
}

.input-field {
  width: 100%; /* 输入框占满容器 */
}

.login-button, .register-button {
  border-radius: 20px;
  width: 100%; /* 按钮占满容器 */
}

.login-button {
  background-color: black; /* 按钮背景色为黑色 */
  color: white; /* 按钮文字颜色为白色 */
  border-color: black; /* 按钮边框颜色与背景色一致 */
}

.login-button:hover {
  background-color: rgba(16, 50, 93); /* 悬停时的背景色 */
}

.register-button {
  background-color: white; /* 按钮背景色为白色 */
  color: black; /* 按钮文字颜色为黑色 */
  border-color: black; /* 按钮边框颜色与背景色一致 */
}

.register-button:hover {
  background-color: black; /* 悬停时的背景色 */
  color: white; /* 悬停时的文字颜色 */
}

/* 底部信息 */
.CopyrightInformation {
  width: 98.8%;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.CopyrightInformation p {
  margin: 0;
  font-size: 14px;
  color: #979899;
  margin-bottom: 10px;
}

.CopyrightInformation a {
  color: #979899;
  text-decoration: none;
  cursor: pointer;
}

.CopyrightInformation a:hover {
  text-decoration: underline;
}

.separator {
  margin: 0 10px;
  color: #979899;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .loginbanner {
    background-size: contain; /* 使背景图片根据容器大小进行缩放 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
  }

  .loginform {
    margin-right: 20px; /* 减小右侧间距 */
    width: 50%; /* 适应屏幕宽度 */
    height: auto; /* 高度自适应 */
    padding: 10px; /* 减小内边距 */
  }

  .loginform h1 {
    margin-top: 20px; /* 减小顶部间距 */
    font-size: 24px; /* 缩小标题字体 */
  }

  .loginform h4 {
    font-size: 14px; /* 缩小副标题字体 */
  }

  .input-field {
    font-size: 14px; /* 缩小输入框字体 */
  }

  .login-button, .register-button {
    font-size: 14px; /* 缩小按钮字体 */
  }

  .CopyrightInformation p {
    font-size: 12px; /* 缩小底部版权信息字体 */
  }
}
</style>
