<template>
  <div class="not-found">
    <div class="container">
      <p class="error-code">404</p>
      <p class="error-message">Sorry, the page you visited doesn't exist!</p>
      <div class="animated-character">
        <img src="@/img/icon/FOFUFOlogo.png" alt="404 Character" />
      </div>
      <router-link to="/" class="home-button">Return to Home</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.container {
  animation: fadeIn 1s ease-in;
}

.error-code {
  font-size: 96px;
  font-weight: bold;
  color: #d9534f;
  margin: 0;
  animation: bounce 1s infinite;
}

.error-message {
  font-size: 24px;
  margin: 20px 0;
  color: #555;
}

.animated-character {
  width: 200px;
  margin: 20px 60px;
  /* animation: swing 1.5s infinite; */
}

.home-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #5cb85c;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #4cae4c;
}

/* Animation keyframes */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes swing {
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(10deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .error-code {
    font-size: 304px; /* Smaller font size for smaller screens */
  }

  .error-message {
    font-size: 20px; /* Smaller font size for message */
  }
  .animated-character {
    width: 150px; /* Reduce character size */
    margin: 20px -540px; /* Adjust margin */
  }

  .home-button {
    padding: 8px 16px; /* Smaller button padding */
  }
}

@media (max-width: 480px) {
  .error-code {
    font-size: 48px; /* Even smaller font size for very small screens */
  }

  .error-message {
    font-size: 18px; /* Smaller message size */
  }

  .animated-character {
    width: 120px; /* Further reduce character size */
  }

  .home-button {
    padding: 6px 12px; /* Adjust button padding */
  }
}
</style>
