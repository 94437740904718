<template>
  <div class="order-page">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <div class="order-title">Order Summary</div>
    <div class="order-content">
      <div class="order-left">
        <div class="shipping-address">
          <div class="section-title">Shipping Address</div>
          <el-select v-model="selectedAddress" placeholder="Select a saved address" class="address-select">
            <el-option
              v-for="address in savedAddresses"
              :key="address.id"
              :label="address.address"
              :value="address.id"
            ></el-option>
          </el-select>
          <div @click="dialogVisible = true" class="add-address-button">
            Add New Address
          </div>
          <el-dialog title="Add New Address" :visible.sync="dialogVisible" width="30%">
            <el-input
              v-model="newAddress.address"
              placeholder="Home Address"
              class="input-field"
            ></el-input>
            <el-input
              v-model="newAddress.deliveryAddress"
              placeholder="Delivery Address"
              class="input-field"
            ></el-input>
            <el-input
              v-model="newAddress.receiverName"
              placeholder="Receiver's Name"
              class="input-field"
            ></el-input>
            <el-input
              v-model="newAddress.telephone"
              placeholder="Telephone Number"
              class="input-field"
            ></el-input>
            <el-input
              v-model="newAddress.zipCode"
              placeholder="ZIP Code"
              class="input-field"
            ></el-input>
            <div slot="footer" class="dialog-footer">
              <div @click="dialogVisible = false" class="dialog-button cancel-button">Cancel</div>
              <div @click="saveNewAddress" class="dialog-button save-button">Save</div>
            </div>
          </el-dialog>
        </div>
        <div class="payment-method">
          <div class="section-title">Expected contact time</div>
          <el-date-picker
          v-model="expectContactTime"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          align="right"
        >
        </el-date-picker>
        </div>
      </div>

      <div class="order-right">
        <div class="product-summary" v-for="item in filteredOrderList" :key="item.skuProperties.id">
          <img :src="item.skuProperties.mainImageUrl" alt="Product Image" class="product-image" />
          <div class="product-details">
            <div class="product-name">{{ item.skuProperties.productsName }}</div>
            <!-- <div class="product-price">${{ item.skuProperties.productsNewPrice }}</div> -->
            <div v-for="(value, key) in item.filteredOrderList" :key="key" class="product-spec">
              {{ key }}: {{ value }}
            </div>
          </div>
        </div>
        <div class="order-total">
          <!-- <div class="subtotal">
            <span>Subtotal</span>
            <span>${{ productPrice }}</span>
          </div>
          <div class="shipping">
            <span>Shipping</span>
            <span>Calculated at next step</span>
          </div> -->
          <div class="total">
            <span>Custom product quantity</span>
            <span>{{ ordernum }}</span>
          </div>
          <div @click="proceedToPayment" class="continue-button">
            Create a custom order
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Seladdress, Postaddress } from '@/api/address/address'
import { Selproducts } from '@/api/cart/cart'
import { PostOrder } from '@/api/order/order'

export default {
  name: 'OrderIndex',
  data () {
    return {
      id: '',
      idsArray: [],
      userAddress: '',
      selectedAddress: null,
      paymentMethod: '2',
      newAddress: {
        address: '',
        deliveryAddress: '',
        receiverName: '',
        telephone: '', // Added telephone field
        zipCode: ''
      },
      savedAddresses: [],
      dialogVisible: false,
      orderlist: [],
      productPrice: '1',
      excludedKeys: ['baseGoodsId', 'productsName', 'mainImageUrl', 'USAstock', 'image', 'productsNewPrice', 'productsOldPrice', 'id'],
      ordernum: '',
      expectContactTime: ['', ''] // 保存选择的日期范围
    }
  },
  created () {
    this.Seladdress()
    this.id = this.$route.params.id
    this.idsArray = JSON.parse(decodeURIComponent(this.id))
    this.Selproducts()
  },
  methods: {
    async Seladdress () {
      const res = await Seladdress()
      this.savedAddresses = res.data
    },
    async saveNewAddress () {
      const res = await Postaddress(
        this.newAddress.address,
        this.newAddress.deliveryAddress,
        this.newAddress.receiverName,
        this.newAddress.telephone, // Include telephone when saving the address
        this.newAddress.zipCode
      )
      if (res.code !== 200) {
        this.dialogVisible = false
        return this.$message.error('Failed to add address, please try again')
      } else {
        this.$message.success('The address was added successfully')
        this.dialogVisible = false
        this.Seladdress()
        this.selectedAddress = res.data
        this.newAddress = {
          address: '',
          deliveryAddress: '',
          receiverName: '',
          telephone: '', // Reset telephone field after saving
          zipCode: ''
        }
      }
    },
    async Selproducts () {
      console.log(this.idsArray)
      this.ordernum = this.idsArray.map(item => item.goodsNumber).reduce((total, item) => {
        return total + item
      }, 0) // 初始值为 0

      const productsid = this.idsArray.map(item => item.dynamicGoodsSkuId)
      const res = await Selproducts(productsid)
      if (res.code === 200) {
        this.orderlist = res.data
        console.log(this.orderlist)

        const totalprice = this.orderlist
          .map(item => item.skuProperties.productsNewPrice)
          .map(price => parseFloat(price))
          .reduce((sum, price) => sum + price, 0)
        this.productPrice = totalprice
      }
    },
    async proceedToPayment () {
      console.log('Expect contact start time:', this.expectContactTime[0])
      console.log('Expect contact end time:', this.expectContactTime[1])
      const expectContactStartTime = this.expectContactTime[0]
      const expectContactEndTime = this.expectContactTime[1]
      const res = await PostOrder(this.idsArray, Number(this.paymentMethod), this.selectedAddress, expectContactStartTime, expectContactEndTime)
      console.log(res)

      if (res.code !== 200) {
        return this.$message.error('The order creation failed, please try again')
      } else {
        this.$message.success('The order is created successfully')
      }
    }

  },
  computed: {
    filteredOrderList () {
      return this.orderlist.map(item => {
        const filteredSku = Object.fromEntries(
          Object.entries(item.skuProperties).filter(([key]) => !this.excludedKeys.includes(key))
        )
        return { ...item, filteredOrderList: filteredSku }
      })
    }
  }
}
</script>

<style scoped>
/* 通用样式 */
.order-page {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.order-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.order-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.order-left,
.order-right {
  width: 48%;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.address-select {
  width: 100%;
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.add-address-button {
  width: 97%;
  padding: 10px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.payment-method{
  margin-top: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end; /* 将按钮靠右对齐 */
  gap: 10px; /* 设置按钮之间的间距 */
}

.dialog-button {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100px; /* 设置按钮宽度，使它们大小一致 */
}

.cancel-button {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.save-button {
  background-color: black;
  color: white;
  border: 1px solid black;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-option input[type="radio"] {
  accent-color: black;
}

.product-summary {
  display: flex;
  margin-bottom: 20px;
}

.product-image {
  width: 130px;
  height: 100px;
  margin-right: 10px;
  border-radius: 5px;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-price {
  font-size: 16px;
  color: #555;
}
.product-num{
  font-size: 16px;
}
.order-total {
  margin-top: 20px;
}

.order-total .subtotal,
.order-total .shipping,
.order-total .total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.order-total .total {
  font-size: 18px;
  font-weight: bold;
}

.continue-button {
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.product-spec{
  font-size: 16px;
  color: #979899;
}

/* 媒体查询，适配移动设备 */
@media (max-width: 768px) {
  .order-page {
    width: 100%;
    padding: 10px;
  }

  .order-content {
    flex-direction: column;
  }

  .order-left,
  .order-right {
    width: 100%;
  }

  .order-total .subtotal,
  .order-total .shipping,
  .order-total .total {
    font-size: 14px;
  }

  .order-total .total {
    font-size: 16px;
  }

  .continue-button {
    font-size: 14px;
    height: 40px;
  }

  .radio-group {
    font-size: 20px;
  }

  .radio-option input {
    width: 30px;
  }

  .add-address-button,
  .continue-button,
  .dialog-button {
    font-size: 14px;  /* Adjust font size for smaller screens */
    padding: 12px 0;  /* Adjust padding for better touch interaction */
    height: auto;  /* Allow buttons to auto-adjust height */
  }

  .dialog-button {
    width: 80px;  /* Reduce button width to fit smaller screens */
  }

  .dialog-footer {
    gap: 5px;  /* Reduce gap between buttons for smaller screens */
  }
}

</style>
