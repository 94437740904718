import request from '@/utils/request'

// 获取分类页场景图
export const getclassify = (tabCode) => {
  return request.post('/business/tab-detail/listPageRecord', {
    tabCode
  })
}

// 单个查询场景图
export const getclassiftimg = (id) => {
  return request.post('/business/tab-detail/findOneRecord', {
    id
  })
}

// 获取商品分类列表
export const getproductslist = () => {
  return request.post('/business/base-goods/listRecord', {
    id: ''
  })
}

// 获取右侧商品分类tab栏
export const getproductsclassifytab = (tabDetailId) => {
  return request.post('/business/tab-detail-goods/listPageRecord', {
    tabDetailId
  })
}

// 获取商品列表
export const getproductsskulist = (baseGoodsId) => {
  return request.post('/business/dynamic-goods-sku/listPageRecord', {
    skuProperties: {
      ParentClass: 'parent',
      baseGoodsId
    }
  })
}

// 查询商品详情
export const getdetail = (id) => {
  return request.post('/business/dynamic-goods-sku/findOneRecord', {
    skuProperties: {
      id
    }
  })
}

// 搜索商品
export const SearchproductList = (productsName, sort) => {
  return request.post('/business/dynamic-goods-sku/listRecord', {
    skuProperties: {
      ParentClass: 'parent',
      productsName
    },
    sort
  })
}

// 查询所有商品
export const selTabproducts = () => {
  return request.post('/business/dynamic-goods-sku/listRecord', {
    skuProperties: {
      ParentClass: 'parent'
    }
  })
}
