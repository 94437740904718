<template>
  <div class="TermsAndPrivacy">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h1>Terms and Conditions</h1>
    <section>
      <h2>1. Terms and Conditions</h2>
      <p>Welcome to our website (the "Site"). By accessing or using the Site, you agree to be bound by these terms and conditions (the "Terms"). If you do not agree with these Terms, please do not use the Site.</p>

      <h3>1.1. Acceptance of Terms</h3>
      <p>By using or accessing the Site, you accept these Terms and agree to comply with them. We may update these Terms from time to time, and your continued use of the Site constitutes your acceptance of such changes.</p>

      <h3>1.2. Use of the Site</h3>
      <p>You agree to use the Site only for lawful purposes and in accordance with all applicable laws and regulations. You are prohibited from using the Site for any illegal or unauthorized purposes.</p>

      <h3>1.3. User Accounts</h3>
      <p>Some features of the Site may require you to create an account. You agree to provide accurate and complete information when creating an account and to update such information as necessary. You are responsible for all activities that occur under your account.</p>

      <h3>1.4. Intellectual Property</h3>
      <p>All content on the Site, including but not limited to text, images, logos, and software, is the property of the Site or its licensors and is protected by copyright and other intellectual property laws. You may not use, copy, reproduce, distribute, or create derivative works of any content on the Site without prior written consent from us or the respective owners.</p>

      <h3>1.5. Disclaimer of Warranties</h3>
      <p>The Site is provided on an "as is" and "as available" basis. We make no warranties or representations of any kind, express or implied, regarding the Site or its content, including but not limited to its accuracy, reliability, or availability.</p>

      <h3>1.6. Limitation of Liability</h3>
      <p>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Site. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses, even if we have been advised of the possibility of such damages.</p>

      <h3>1.7. Termination</h3>
      <p>We reserve the right to terminate or suspend your access to the Site at any time, without notice, for any reason, including but not limited to a breach of these Terms.</p>

      <h3>1.8. Governing Law</h3>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Your Location].</p>

      <h2>2. Return and Refund Policy</h2>
      <h3>2.1. Return Conditions</h3>
      <p>Our website does not process returns or refunds directly. If you wish to return a product or request a refund, please contact us offline at xiaoc2525@gmail.com. We will assist you with the process and discuss the details directly with you.</p>

      <h3>2.2. Refund Process</h3>
      <p>Refunds, if applicable, will be processed offline. We will communicate with you directly to handle the refund process and provide any necessary instructions.</p>

      <h3>2.3. Non-Returnable Items</h3>
      <p>Certain types of goods are exempt from being returned, such as perishable goods, customized products, and items marked as final sale. Please refer to the specific product details for more information.</p>

      <h3>2.4. Return Shipping</h3>
      <p>You will be responsible for paying for your own shipping costs for returning items. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>

      <h3>2.5. Refund Process</h3>
      <p>Once we’ve received and inspected your return, we will notify you of the refund status. Refunds will be processed offline, and you will receive instructions accordingly.</p>

      <h2>3. Shipping Policy</h2>
      <h3>3.1. Order Processing</h3>
      <p>All orders are customized based on your specifications placed online. The customization fee is settled offline. After completing the customization, you will be contacted by a dedicated representative to finalize the details and arrange shipping.</p>

      <h3>3.2. Shipping Rates & Delivery Estimates</h3>
      <p>Shipping charges will be calculated based on the final arrangements discussed with you. Delivery times are estimates and will commence from the date of shipping rather than the date of the order. Delivery times may vary based on location and other factors.</p>

      <h3>3.3. Shipment Confirmation & Order Tracking</h3>
      <p>You will receive a shipment confirmation email once your order has shipped containing your tracking number(s). The tracking number will be active within 24 hours.</p>

      <h3>3.4. Customs, Duties, and Taxes</h3>
      <p>We are not responsible for any customs duties, taxes, or fees applied to your order. All additional charges imposed during or after shipping are the responsibility of the customer.</p>

      <h3>3.5. Damages</h3>
      <p>If you receive a damaged order, please contact us immediately with photographic evidence. We will work with the shipping carrier to resolve the issue.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TermsAndPrivacy'
}
</script>

<style scoped>
.TermsAndPrivacy {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  border-radius: 8px;
  animation: fadeIn 1s ease-in-out;
}

h1, h2, h3 {
  color: #333;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

h1:hover, h2:hover, h3:hover {
  color: #007BFF;
}

p {
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Media Queries */
@media (max-width: 1200px) {
  .TermsAndPrivacy {
    padding: 15px;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .TermsAndPrivacy {
    padding: 10px;
  }
}
</style>
