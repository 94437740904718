import request from '@/utils/request'
import store from '@/store' // 导入 Vuex store
// 注册
export const getregister = (countryCode, country, email, telephone, userName, userPassword) => {
  return request.post('/user/sys-user/registerUser', {
    clientId: 'fohufo_app',
    country,
    countryCode,
    email,
    telephone,
    userName,
    userPassword
  })
}

// 登录
export const getlogin = (password, userAccount) => {
  return request.post('/user/authTokenByPassword', {
    clientId: 'fohufo_app',
    loginType: 1,
    password,
    userAccount
  })
}

// 获取用户信息
export const getuserinfo = () => {
  return request.post('/user/sys-user/getUserInfo')
}

// 修改用户信息
export const changeuserinfo = (photo) => {
  return request.post('/user/sys-user/updateUserInfo', {
    photo
  })
}

// 文件上传
export const getuploadfile = (formData) => {
  return request.post('/component/minio/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data' // 确保内容类型为 multipart/form-data
    }
  })
}

// 刷新token
export const Refreshtoken = () => {
  return request.post('/user/refreshToken', {
    clientId: 'fohufo_app',
    loginType: 1,
    refreshToken: store.state.user.refreshToken
  })
}
