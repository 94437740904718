import { getInfo, setInfo, removeInfo } from '@/utils/storage'

export default {
  namespaced: true,
  state () {
    const info = getInfo()
    return {
      token: info.token || '',
      refreshToken: info.refreshToken || ''
    }
  },
  mutations: {
    setUserinfo (state, userInfo) {
      state.token = userInfo.access_token
      state.refreshToken = userInfo.refresh_token
      setInfo({
        token: userInfo.access_token,
        refreshToken: userInfo.refresh_token
      })
    },
    clearUserinfo (state) {
      state.token = ''
      state.refreshToken = ''
      removeInfo()
    }
  },
  actions: {
    login ({ commit }, userInfo) {
      commit('setUserinfo', userInfo)
    },
    logout ({ commit }) {
      commit('clearUserinfo')
    }
  },
  getters: {
    isLoggedIn: state => !!state.token // 检查用户是否登录
  }
}
