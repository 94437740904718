<template>
  <div class="Policy">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h1>Privacy Policy</h1>
    <section>
      <p>This Privacy Policy ("Policy") explains how we collect, use, and protect your personal information when you visit our website (the "fohufo"). By using the Site, you agree to the terms of this Policy.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Your name, email address, phone number, payment information, etc.</li>
        <li><strong>Usage Data:</strong> Information about your interactions with the Site, including IP address, browser type, and browsing history.</li>
        <li><strong>Cookies:</strong> Small data files stored on your device to enhance user experience.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>Your information is used to:</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Process transactions and communicate with you about your account or orders.</li>
        <li>Analyze usage data to optimize the Site's performance.</li>
        <li>Comply with legal requirements and enforce our policies.</li>
      </ul>

      <h2>3. Information Sharing</h2>
      <p>We do not sell or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating the Site, as long as they agree to keep your information confidential.</p>

      <h2>4. Data Security</h2>
      <p>We implement a variety of security measures to protect your personal information. However, please be aware that no method of online transmission or storage is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>You may have rights under applicable laws to access, update, or delete your personal information. You can also opt out of receiving marketing communications from us at any time.</p>

      <h2>6. Changes to This Policy</h2>
      <p>We may update this Policy periodically. Any changes will be posted on this page with an updated revision date. Your continued use of the Site after changes are made will be considered acceptance of the new Policy.</p>

      <h2>7. Transfer of Data to Third Parties</h2>
      <h3>7.1 Transfer only where allowed by law</h3>
      <p>We only forward your data if this is allowed by German or European law. We work particularly closely with certain service providers, such as customer service and logistics companies. These service providers may process your data on our behalf under specific conditions and only within the scope and time needed to provide their services.</p>

      <h3>7.2 Group companies</h3>
      <p>Companies within our group may receive access to your data when necessary to fulfill our contractual obligations or perform specific functions within the group.</p>

      <h3>7.3 Technical service providers</h3>
      <p>We work with technical service providers to deliver our services. These include partners like [SHOPLINE]. If processing occurs outside the EU, we ensure equivalent data protection levels through appropriate contractual agreements.</p>

      <h3>7.4 Payment service providers and credit agencies</h3>
      <p>We do not process payments directly on the website. After you register an account and submit your intention order in the shopping cart, we will contact you via email to negotiate and complete the custom payment process.</p>

      <h3>7.5 Shipping companies</h3>
      <p>We collaborate with external shipping companies  to deliver orders. These companies may receive your name, delivery address, and email for delivery purposes.</p>

      <h3>7.6 Authorities and other third parties</h3>
      <p>We may disclose your data to law enforcement or other third parties when required by law or court order.</p>

      <h2>8. Retention and Erasure of Data</h2>
      <p>Your personal data is retained as long as necessary to fulfill our contractual and legal obligations. Upon account closure, we will delete your data unless legally required to retain it for a specific time period.</p>

      <h2>9. How is My Personal Data Protected?</h2>
      <p>We use technical and organizational measures, including SSL encryption, to protect your data during transmission and storage.</p>

      <h2>10. Your Rights</h2>
      <p>You have the right to access, rectify, or delete your data, as well as restrict or object to its processing. You may also request data portability or withdraw consent for data processing at any time.</p>

      <h3>10.1 Withdrawal of consent</h3>
      <p>If you have given consent to the processing of your data, you can revoke it at any time. This will not affect the legality of the processing prior to withdrawal.</p>

      <h2>11. Changes to this Privacy Policy</h2>
      <p>We may update this privacy policy due to improvements in our website or changes in legal requirements. We encourage you to review this notice regularly.</p>

      <h2>12. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at: <a href="mailto:modernfurniture1999@bestfurnitureland.com">modernfurniture1999@bestfurnitureland.com</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PolicyIndex'
}
</script>

<style scoped>
.Policy {
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  border-radius: 10px;
  animation: slideIn 0.8s ease;
}

h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 10px;
}

h2 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.25rem;
  color: #34495e;
  margin-bottom: 10px;
}

p {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}

ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
  color: #95a5a6;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .Policy {
    padding: 15px;
  }

  h1 {
    font-size: 20px;
  }

  h2, h3 {
    font-size: 19px;
  }

  p, ul {
    font-size: 17px;
  }
}
</style>
