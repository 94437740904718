import request from '@/utils/request'

// 获取轮播图
export const getbanner = () => {
  return request.post('/business/banner/listRecord', {
    type: 1
  })
}

// 导航栏分类
export const getnavabar = () => {
  return request.post('/business/tab/listRecord', {
    tabCode: ''
  })
}

// 查询单个导航栏
export const getnavabarlist = (tabCode) => {
  return request.post('/business/tab/findOneRecord', {
    tabCode
  })
}
