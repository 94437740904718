<template>
  <div class="blog-detail">
    <HeaderInformation></HeaderInformation>
    <!-- 内容部分 -->
    <div class="content">
      <p class="title">{{ post.title }}</p>
      <p class="date">{{ post.updateTime }}</p>
      <div class="post-content" v-html="post.content">
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import { findblogOne } from '@/api/blog/blog'
export default {
  name: 'BlogdetailIndex',
  data () {
    return {
      id: this.$route.params.id,
      post: { }
    }
  },
  components: {
    HeaderInformation
  },
  created () {
    console.log(this.id)
    // 你可以在此处根据 this.id 来请求详细数据
    this.findblogOne()
  },
  methods: {
    async findblogOne () {
      const res = await findblogOne(this.id)
      console.log(res)
      this.post = res.data
    }
  }
}
</script>

<style scoped>
/* General styles for all screen sizes */
.blog-detail {
  padding: 1rem; /* Responsive padding */
}

/* Centering the content section */
.content {
  /* background-color: aqua; */
  margin-left: -10px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  text-align: center; /* Center the text inside the content */
}

.title {
  font-size: 1.75rem; /* Responsive font size */
  font-weight: bold;
  margin: 1rem 0;
  /* background-color: aqua; */
  flex-grow: initial;
}

.date {
  font-size: 0.875rem; /* Responsive font size */
  color: #888;
  margin-bottom: 15px;
}

/* Responsive post content */
.post-content {
  text-align: left;
  line-height: 1.6;
  max-width: 100%; /* Ensure it takes full width on mobile */
  padding: 0 10px;
}

.post-content p {
  margin-bottom:12px;
}

/* Responsive image styling */
.content-image {
  width: 100%; /* Ensure images are responsive */
  height: auto;
  margin-top: 1rem;
  border-radius: 5px;
}

/* Media queries for responsive design */
@media (min-width: 320px) {
  .content {
    margin-top: 12px;
  }

  .title {
    font-size: 22px;
  }

  .date {
    font-size: 20px;
  }

  .post-content {
    font-size: 18px;
    max-width: 80%; /* Wider content area on tablets */
  }
  .post-content img{
    width: 1050px;
    height: 600px;
  }
}

@media (min-width: 1024px) {
  .content {
    margin-top: 2rem;
  }

  .title {
    font-size: 2.25rem;
  }

  .date {
    font-size: 1rem;
  }

  .post-content {
    max-width: 900px; /* Set max width for larger screens */
    padding: 0 0; /* Increase padding */
  }
}
</style>
